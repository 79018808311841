export const constructURLWithParams = (
  baseUrl: string,
  queryParams: Record<string, string | undefined | null>
) => {
  const params = new URLSearchParams()

  for (const [key, value] of Object.entries(queryParams)) {
    if (value && value.trim() !== '') {
      params.append(key, value)
    }
  }

  return params.toString() ? `${baseUrl}?${params.toString()}` : baseUrl
}
