import type { CategoryDTO } from 'ecosystem'

export const filterCategories = (categories?: CategoryDTO[] | null): CategoryDTO[] => {
  if (!categories) {
    return []
  }

  return sortCategories(categories).map((category) => {
    return {
      ...category,
      ...(category.subCategories?.length
        ? {
            subCategories: filterCategories(category.subCategories)
          }
        : {})
    }
  })
}
export const sortCategories = (categories: CategoryDTO[]) =>
  categories.filter((c) => c.order).sort((a, b) => a.order - b.order)

export const flattenCategories = (categories?: CategoryDTO[] | null) => {
  const res: CategoryDTO[] = []

  const flatFn = (_categories?: CategoryDTO[] | null) => {
    if (!_categories) {
      return
    }

    _categories.forEach((category) => {
      res.push(category)

      flatFn(category.subCategories)
    })
  }

  flatFn(categories)

  return res
}
