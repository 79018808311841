'use client'
import type { Context, Reducer } from 'react'
import { useCallback, useReducer } from 'react'
import type { ModalContextProps, ModalContextType, ModalPayloadProps, ModalState } from './types'

interface ModalProviderProps<C> extends ModalContextProps {
  context: Context<ModalContextType<C> | null>
}

const reducer = <C, E>(state: ModalState<C, E>, newState: Partial<ModalState<C, E>>) => {
  return { ...state, ...newState }
}

const initialState: ModalState<null, null> = {
  isHidden: true,
  size: 'lg',
  title: '',
  componentId: null,
  extraArgs: null
}

const ModalProvider = <C,>({ children, context }: ModalProviderProps<C>) => {
  const [state, dispatch] = useReducer<Reducer<ModalState<C | null, any>, any>>(
    reducer,
    initialState
  )

  const setModalOpen = useCallback((): void => {
    dispatch({ isHidden: false })
  }, [])

  const setModalClose = useCallback((): void => {
    dispatch({ isHidden: true })
  }, [])

  const setModalChildren = useCallback(<E,>(payload: ModalPayloadProps<C, E>): void => {
    dispatch(payload)
  }, [])

  return (
    <context.Provider
      value={{
        ...state,
        setModalOpen,
        setModalClose,
        setModalChildren
      }}>
      {children}
    </context.Provider>
  )
}

export default ModalProvider
