import type { FC } from 'react'
import type { GrandImageProps } from './GrandImage'
import { GrandImage } from './GrandImage'

export type HeroImageProps = GrandImageProps

const HeroImage: FC<HeroImageProps> = (props) => {
  return <GrandImage fill objectFit="contain" priority quality={100} sizes="100vw" {...props} />
}

export default HeroImage
