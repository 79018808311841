import type { DividerProps } from '@chakra-ui/react'
import { Divider } from '@chakra-ui/react'
import type { FC } from 'react'

export type GrandDividerProps = DividerProps

export const GrandDivider: FC<GrandDividerProps> = (props) => {
  return (
    <Divider
      borderColor="background.surface"
      borderWidth={4}
      boxSizing="border-box"
      transition="all 0.1s cubic-bezier(.17,.67,.83,.67)"
      {...props}
    />
  )
}
