/* eslint-disable import/no-duplicates -- https://github.com/import-js/eslint-plugin-import/issues/1479 */
import lightDateFnsFormat from 'date-fns/lightFormat'
import isValidDateFns from 'date-fns/isValid'
/* eslint-enable import/no-duplicates -- end */

export const isDateWeekend = (date: Date) => {
  const day = date.getDay()
  // 6 - Saturday, 0 - Sunday
  return day === 6 || day === 0
}

export const lightFormatDate = (date: string | Date = '', format = 'yyyy-MM-dd') => {
  if (typeof date === 'string') {
    const parsedDate: number = Date.parse(date)

    return Number.isNaN(parsedDate) ? '' : lightDateFnsFormat(parsedDate, format)
  }

  return isValidDateFns(date) ? lightDateFnsFormat(date, format) : ''
}
