import type { FC } from 'react'
import type { StackProps } from '@chakra-ui/react'
import { Fade, Stack, useDisclosure } from '@chakra-ui/react'

type IAnimatedContainerProps = StackProps

const AnimatedContainer: FC<IAnimatedContainerProps> = ({ children, ...props }) => {
  const { isOpen } = useDisclosure({ defaultIsOpen: true })

  return (
    <Stack {...props}>
      <Fade
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -10, opacity: 0 }}
        in={isOpen}
        initial={{ y: 10, opacity: 0 }}>
        {children}
      </Fade>
    </Stack>
  )
}

export default AnimatedContainer
