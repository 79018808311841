import { Box, type BoxProps, Button, Flex, HStack, Progress, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { clsx } from 'clsx'
import { createCn, percentage } from 'shared-utils'
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi'
import { useIsomorphicLayoutEffect } from '../../hooks'
import useBoundingRect from './useBoundingRect'

const cn = createCn('carousel-slider')

export type CarouselSliderProps = Omit<BoxProps, 'gap'> & {
  setTrackIsActive: any
  initSliderWidth: (width: number) => void
  setActiveItem: any
  activeItem: any
  constraint: any
  itemWidth: any
  positions: any
  children: any
  gap: number
  showItemCount?: boolean
  showCarouselProgress?: boolean
}

const CarouselSlider: FC<CarouselSliderProps> = ({
  setTrackIsActive,
  initSliderWidth,
  setActiveItem,
  activeItem,
  constraint,
  itemWidth,
  positions,
  children,
  gap,
  showItemCount,
  showCarouselProgress,
  className,
  ...props
}) => {
  const [ref, { width }] = useBoundingRect()
  const [isInitialized, setIsInitialized] = useState(false)

  // Utilize useEffect to only set initialization after the first render
  useEffect(() => {
    if (width && !isInitialized) {
      initSliderWidth(Math.round(width))
      setIsInitialized(true)
    }
  }, [width, initSliderWidth, isInitialized])

  useIsomorphicLayoutEffect(() => {
    if (isInitialized) {
      initSliderWidth(Math.round(width))
    }
  }, [width, initSliderWidth, isInitialized])

  const handleFocus = () => setTrackIsActive(true)

  const handleDecrementClick = () => {
    setTrackIsActive(true)
    !(activeItem === positions.length - positions.length) && setActiveItem((prev: any) => prev - 1)
  }

  const handleIncrementClick = () => {
    setTrackIsActive(true)
    !(activeItem === positions.length - constraint) && setActiveItem((prev: number) => prev + 1)
  }

  return (
    <Box className={clsx(cn(), className)} {...props}>
      <HStack className={cn('container')} flex={1}>
        <Button
          _focus={{ outline: 'none' }}
          aria-label="prev"
          bg="transparent"
          color="text.default"
          minW={0}
          mr={`${gap / 3}px`}
          onClick={handleDecrementClick}
          onFocus={handleFocus}
          variant="link">
          <BiChevronLeft color="text.default" size={30} />
        </Button>

        <Box
          _after={{
            bgGradient: 'linear(to-l, background.default, transparent)',
            position: 'absolute',
            w: `${gap / 2}px`,
            content: "''",
            zIndex: 1,
            h: '100%',
            right: 0,
            top: 0
          }}
          _before={{
            bgGradient: 'linear(to-r, background.default, transparent)',
            position: 'absolute',
            w: `${gap / 2}px`,
            content: "''",
            zIndex: 1,
            h: '100%',
            left: 0,
            top: 0
          }}
          bg="background.default"
          className={cn('content')}
          ml={{ base: 0, md: `-${gap / 2}px` }}
          overflow="hidden"
          position="relative"
          px={`${gap / 2}px`}
          ref={ref}
          minH={220}
          h="full"
          w={{ base: '100%', md: `calc(100% + ${gap}px)` }}>
          {children}
        </Box>

        <Button
          _focus={{ outline: 'none' }}
          aria-label="next"
          bg="transparent"
          color="text.default"
          minW={0}
          ml={`${gap / 3}px`}
          onClick={handleIncrementClick}
          onFocus={handleFocus}
          variant="link"
          zIndex={2}>
          <BiChevronRight color="text.default" size={30} />
        </Button>
      </HStack>

      <Flex direction="column" ml="auto" mr="auto" mt={`${gap / 2}px`} w={`${itemWidth}px`}>
        {showCarouselProgress && (
          <Box>
            <Progress
              alignSelf="center"
              aria-label="carousel progress"
              bg="background.surface"
              borderRadius="2px"
              flex={1}
              h="3px"
              sx={{
                '> div': {
                  transition: 'all 0.1s cubic-bezier(.17,.67,.83,.67)',
                  backgroundColor: 'primary',
                  opacity: 0.75
                }
              }}
              value={percentage(activeItem, positions.length - constraint)}
            />
          </Box>
        )}

        {showItemCount && (
          <Text
            color="text.darkGrey"
            fontFamily="heading"
            fontSize="xl"
            fontWeight="medium"
            mt={6}
            textAlign="center"
            whiteSpace="nowrap">
            {(activeItem as number) + 1} / {positions.length}
          </Text>
        )}
      </Flex>
    </Box>
  )
}

export default CarouselSlider
