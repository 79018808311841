'use client'
import { isUndefined } from 'shared-utils'
import lodashMerge from 'lodash/merge'
import type { ProviderProps } from 'react'
import { createContext } from '../react-utils'
import type { OverrideComponentPropsContextValue } from './types'

const [CustomProvider, useContext] = createContext<OverrideComponentPropsContextValue | undefined>({
  strict: false,
  name: 'OverrideComponentProps'
})

// Provider
export function OverrideComponentPropsProvider({
  value,
  children
}: ProviderProps<OverrideComponentPropsContextValue>) {
  const parentContextValue = useContext()

  const mergedValue = isUndefined(parentContextValue)
    ? value
    : lodashMerge({ ...parentContextValue }, value)

  return <CustomProvider value={mergedValue}>{children}</CustomProvider>
}

// hook
export function useOverrideComponentPropsContext<
  Name extends keyof OverrideComponentPropsContextValue
>(componentName: Name): OverrideComponentPropsContextValue[Name] | undefined

export function useOverrideComponentPropsContext<
  Name extends keyof OverrideComponentPropsContextValue,
  OrProps
>(componentName: Name, originalProps: OrProps): OrProps

export function useOverrideComponentPropsContext<
  Name extends keyof OverrideComponentPropsContextValue,
  OrProps
>(
  componentName: Name,
  originalProps?: OrProps
): OrProps | OverrideComponentPropsContextValue[Name] | undefined {
  const context = useContext()

  const overriddenProps = context?.[componentName]

  if (isUndefined(overriddenProps)) {
    return originalProps
  }

  return lodashMerge({ ...originalProps }, overriddenProps)
}
