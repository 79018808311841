'use client'
import { usePathname } from 'next/navigation'
import { useEffect } from 'react'
import { usePrevious } from '@chakra-ui/react'
import { isUndefined } from 'shared-utils'
import { useRefUpdated } from './useRefUpdated'

export interface UsePathnameChangedCbParams {
  data: {
    pathname: string
    prevPathname?: string
  }
  meta: {
    isInitialLoad: boolean
    isPathChanged: boolean
  }
}

export const usePathnameChanged = (cb: (params: UsePathnameChangedCbParams) => void) => {
  const pathname = usePathname()
  const cbRef = useRefUpdated(cb)

  const prevPathname = usePrevious(pathname)

  useEffect(() => {
    if (cbRef.current) {
      const isInitialLoad = isUndefined(prevPathname)

      if (!prevPathname || !pathname) return

      cbRef.current({
        data: {
          pathname,
          prevPathname
        },
        meta: {
          isInitialLoad,
          isPathChanged: !isInitialLoad && prevPathname !== pathname
        }
      })
    }
  }, [cbRef, pathname, prevPathname])
}
