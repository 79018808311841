import { useEffect } from 'react'
import { usePathname } from 'next/navigation'
import { useDebouncedCallback } from 'use-debounce'

function saveScrollPos(asPath: string) {
  sessionStorage.setItem(
    `scrollPos:${asPath}`,
    JSON.stringify({ x: window.scrollX, y: window.scrollY })
  )
}

function restoreScrollPos(asPath: string, delay?: number, cb?: () => void) {
  const json = sessionStorage.getItem(`scrollPos:${asPath}`)
  const scrollPos = json ? JSON.parse(json) : undefined

  if (scrollPos) {
    if (delay) {
      setTimeout(() => {
        window.scrollTo(scrollPos.x, scrollPos.y)
        cb?.()
      }, delay)
    } else {
      window.scrollTo(scrollPos.x, scrollPos.y)
      cb?.()
    }
  } else {
    cb?.()
  }
}

export function useScrollRestoration() {
  const pathname = usePathname()

  const debouncedSaveScroll = useDebouncedCallback((path: string) => {
    saveScrollPos(path)
  }, 300)

  useEffect(() => {
    if (!('scrollRestoration' in window.history)) return
    window.history.scrollRestoration = 'manual'

    let isRestored = false

    if (pathname) {
      restoreScrollPos(pathname, 200, () => {
        isRestored = true
      })
    }

    const handleScroll = () => {
      if (isRestored && pathname) {
        debouncedSaveScroll(pathname)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [debouncedSaveScroll, pathname])
}
