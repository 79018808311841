'use client'
import type { FC, ReactElement } from 'react'
import { useMemo, useState } from 'react'
import { Box } from '@chakra-ui/react'
import { isMobile } from 'react-device-detect'
import useElementSize from '../hooks/useElementSize'
import type { IImageBoxProps } from './ImageBox'
import ImageBox from './ImageBox'

interface IHoverableImagesProps extends Partial<IImageBoxProps> {
  firstImgUrl: string | null
  secondImgUrl: string | null
  title: string
  alt: string
  badges?: ReactElement
}

const HoverableImages: FC<IHoverableImagesProps> = ({
  firstImgUrl,
  secondImgUrl,
  title,
  alt,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars -- Destructuring this prop
  alignItems,
  badges,
  imageProps: { style, ...otherImageProps } = {},
  ...props
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const [squareRef, { width, height }] = useElementSize()

  const needRenderSecondImg = useMemo(() => {
    return Boolean(secondImgUrl) && !isMobile
  }, [secondImgUrl])

  const willHover = useMemo(() => {
    return needRenderSecondImg && isHovered
  }, [isHovered, needRenderSecondImg])

  return (
    <Box
      onMouseEnter={() => {
        setIsHovered(true)
      }}
      onMouseLeave={() => {
        setIsHovered(false)
      }}
      overflow="hidden"
      position="relative"
      ref={squareRef}
      w="100%">
      {needRenderSecondImg ? (
        <ImageBox
          bottom={0}
          className="hoverable-images__back-image"
          cursor="pointer"
          imageProps={{
            src: secondImgUrl || '',
            quality: 30,
            style: {
              objectFit: 'contain',
              ...style
            },
            sizes: '(max-width: 40em) 100vw, (max-width: 52em) 40vw, 20vw',
            ...otherImageProps
          }}
          left={0}
          opacity={isHovered ? 1 : 0}
          p={{
            base: 0,
            md: 0
          }}
          position="absolute"
          right={0}
          top={0}
          transition="all 0.1s ease-out"
          {...{ width, height }}
          {...props}
        />
      ) : null}
      <ImageBox
        _hover={{
          opacity: willHover ? 0 : 1
        }}
        className="hoverable-images__front-image"
        cursor="pointer"
        imageProps={{
          title: title ? title : '',
          alt,
          src: firstImgUrl || '',
          quality: 30,
          style: {
            objectFit: 'contain',
            ...style
          },
          sizes: '(max-width: 40em) 100vw, (max-width: 52em) 40vw, 20vw',
          ...otherImageProps
        }}
        p={{
          base: 0,
          md: 0
        }}
        transition="all 0.1s ease-in"
        w="100%"
        {...{ badges }}
        {...props}
      />
    </Box>
  )
}

export default HoverableImages
