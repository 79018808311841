'use client'
import { createContext } from 'react'
import type { SlideOutContextType } from './types'

export const createSlideOutContext = <C, F>() => {
  return createContext<SlideOutContextType<C, F> | null>(null)
}

export function SlideOutContextProvider<C, F>() {
  const SlideOutContext = createSlideOutContext<C, F>()

  return {
    SlideOutContext
  }
}
