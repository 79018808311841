import type { FC } from 'react'
import type { DividerProps, HeadingProps, StackProps } from '@chakra-ui/react'
import { Heading, VStack } from '@chakra-ui/react'
import { GrandDivider } from './GrandDivider'
import GrandTooltip from './GrandTooltip'

export interface IGrandHeadingProps extends HeadingProps {
  title: string
  tooltip?: string
  withDecoration?: boolean
  containerProps?: StackProps
  decorationProps?: DividerProps
  headingTag?: any
}

const GrandHeading: FC<IGrandHeadingProps> = ({
  containerProps,
  decorationProps,
  title,
  tooltip,
  withDecoration,
  headingTag,
  fontSize,
  ...props
}) => {
  const heading = (
    <Heading
      as={headingTag || 'h2'}
      color="text.default"
      fontSize={
        fontSize ?? {
          base: '2xl',
          md: '3xl'
        }
      }
      fontWeight="medium"
      w="100%"
      {...props}>
      {title}
    </Heading>
  )

  return (
    <VStack mr={2} my={3} w="100%" {...containerProps}>
      {tooltip ? <GrandTooltip label={tooltip}>{heading}</GrandTooltip> : heading}

      {withDecoration ? (
        <GrandDivider
          borderColor="primary"
          borderWidth={1}
          opacity={1}
          w="50%"
          {...decorationProps}
        />
      ) : null}
    </VStack>
  )
}

export default GrandHeading
