import type { FC } from 'react'
import type { StackProps } from '@chakra-ui/react'
import { Stack } from '@chakra-ui/react'
import { MAX_CONTENT_WIDTH } from '../../constants'

export type ContentContainerProps = StackProps & {
  noWidthLimit?: boolean
}

const ContentContainer: FC<ContentContainerProps> = ({ noWidthLimit, ...props }) => {
  const maxW = noWidthLimit ? 'auto' : MAX_CONTENT_WIDTH

  return (
    <Stack
      bg="none"
      direction="row"
      ml="auto"
      mr="auto"
      p={{
        base: 4,
        md: 6
      }}
      w="100%"
      {...{ maxW }}
      {...props}>
      {props.children}
    </Stack>
  )
}

export default ContentContainer
