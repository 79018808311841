import type { FC } from 'react'
import type { SpinnerProps, StackProps } from '@chakra-ui/react'
import { Spinner, Text, VStack } from '@chakra-ui/react'

interface IActivityLoader extends StackProps {
  text: string
  spinnerProps?: SpinnerProps
}

const ActivityLoader: FC<IActivityLoader> = ({ text, spinnerProps, ...props }) => {
  return (
    <VStack
      alignItems="center"
      fontSize="lg"
      h="100vh"
      justifyContent="center"
      position="absolute"
      spacing={25}
      w="100vw"
      zIndex={9999}
      {...props}>
      <Spinner size="xl" {...spinnerProps} />
      <Text fontStyle="italic">{text}</Text>
    </VStack>
  )
}

export default ActivityLoader
