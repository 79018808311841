import type { FC, ReactNode } from 'react'
import type { GrandTextProps } from './GrandText'
import GrandText from './GrandText'

interface ResponsiveTextBoxProps extends GrandTextProps {
  children: ReactNode
}

const ResponsiveTextBox: FC<ResponsiveTextBoxProps> = ({ children, ...props }) => {
  return (
    <GrandText
      bg={{
        base: 'none'
      }}
      fontSize={{
        base: '3xl',
        md: '4xl'
      }}
      fontWeight="medium"
      letterSpacing="wider"
      lineHeight={{
        base: '1.2em'
      }}
      py={{
        base: 1,
        md: 3
      }}
      textAlign="center"
      zIndex={2}
      {...props}>
      {children}
    </GrandText>
  )
}

export default ResponsiveTextBox
