'use client'
import type { Context } from 'react'
import { useCallback, useContext } from 'react'
import type {
  SlideOutContextType,
  SlideOutPayloadProps,
  SlideoutSize,
  SlideOutState
} from './types'

export interface UseSlideOutHookController<C, F> extends SlideOutState<C, F, unknown> {
  closeSlideOut: () => void
  setSlideOutSize: (size: SlideoutSize) => void
}

export interface UseSlideOutHook<C, F> extends UseSlideOutHookController<C, F> {
  openSlideOut: <E>(payload: SlideOutPayloadProps<C, F, E>) => void
  overrideComponent: <E>(payload: SlideOutPayloadProps<C, F, E>) => void
}

export const useContextSlideOut = <C, F>(
  context: Context<SlideOutContextType<C, F> | null>
): UseSlideOutHook<C, F> => {
  const ctx = useContext(context)

  if (!ctx) throw Error('No context found.')

  const {
    setSlideOutChildren,
    setSlideOutOpen,
    setSlideOutClose,
    setSlideOutSize: setSize,
    ...state
  } = ctx

  const closeSlideOut = useCallback(() => {
    setSlideOutClose()
  }, [setSlideOutClose])

  const openSlideOut = useCallback(
    <E>(payload: SlideOutPayloadProps<C, F, E>) => {
      setSlideOutChildren(payload)
      setSlideOutOpen()
    },
    [setSlideOutChildren, setSlideOutOpen]
  )

  const setSlideOutSize = useCallback(
    (payload: SlideoutSize) => {
      setSize(payload)
    },
    [setSize]
  )

  const overrideComponent = useCallback(
    <E>(payload: SlideOutPayloadProps<C, F, E>) => {
      setSlideOutChildren(payload)
    },
    [setSlideOutChildren]
  )

  return {
    closeSlideOut,
    openSlideOut,
    setSlideOutSize,
    overrideComponent,
    ...state
  }
}
