import type { FC } from 'react'
import type { FormControlProps, NumberInputProps } from '@chakra-ui/react'
import {
  FormControl,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper
} from '@chakra-ui/react'

export interface INumberSelectorProps extends NumberInputProps {
  id: string
  hiddenLabel: string
  formControlProps?: FormControlProps
}

const NumberSelector: FC<INumberSelectorProps> = ({
  id,
  hiddenLabel,
  formControlProps = {},
  ...props
}) => {
  return (
    <FormControl position="relative" {...formControlProps}>
      <FormLabel
        bottom="100%"
        color="text.mild"
        fontSize={{ base: 'xx-small', md: 'xs' }}
        htmlFor={id}
        left="0"
        mb="0.3em"
        position="absolute">
        {hiddenLabel}
      </FormLabel>
      <NumberInput defaultValue={1} maxW={24} min={1} size="lg" {...props}>
        <NumberInputField borderRadius="sm" {...{ id }} />
        <NumberInputStepper>
          <NumberIncrementStepper color="text.default" />
          <NumberDecrementStepper color="text.default" />
        </NumberInputStepper>
      </NumberInput>
    </FormControl>
  )
}

export default NumberSelector
