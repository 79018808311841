'use client'
import type { ForwardedRef, MutableRefObject } from 'react'
import { useMemo } from 'react'
import CreatableSelect from 'react-select/creatable'
import Select from 'react-select'
import type SelectComponentType from 'react-select/dist/declarations/src/Select'
import type { StateManagerProps } from 'react-select/dist/declarations/src/stateManager'
import { forwardRef } from '@chakra-ui/react'
import type { CreatableAdditionalProps } from 'react-select/dist/declarations/src/useCreatable'
import type { GroupBase } from 'react-select/dist/declarations/src/types'

/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint -- some errors during parsing */
export interface GrandSelectSimpleOption<Value> {
  label: string
  value: Value
}

export type GrandSelectElement<
  Option = unknown,
  IsMulti extends boolean = false
> = SelectComponentType<Option, IsMulti>
export type GrandSelectRef<Option = unknown, IsMulti extends boolean = false> = MutableRefObject<
  GrandSelectElement<Option, IsMulti>
>

export type GrandSelectProps<Option = unknown, IsMulti extends boolean = false> = StateManagerProps<
  Option,
  IsMulti
> &
  CreatableAdditionalProps<Option, GroupBase<Option>> & { isCreatable?: boolean }

const _GrandSelect = <Option extends unknown = unknown, IsMulti extends boolean = false>(
  { styles = {}, isCreatable, ...props }: GrandSelectProps<Option, IsMulti>,
  ref: ForwardedRef<SelectComponentType<Option, IsMulti>>
) => {
  const { control, menu, multiValue, multiValueRemove, multiValueLabel, ...restStyles } = styles

  const SelectComponent = useMemo(() => (isCreatable ? CreatableSelect : Select), [isCreatable])

  return (
    <SelectComponent<Option, IsMulti>
      formatCreateLabel={(inputValue) => `Skapa ny "${inputValue}"`}
      placeholder="Välj eller ange..."
      ref={ref}
      styles={{
        control: (baseStyles, state) => {
          const focusColor = 'var(--chakra-colors-input-focus, var(--chakra-colors-blue-500))'

          return {
            ...baseStyles,
            minHeight: '40px',
            minWidth: '150px',
            width: '100%',
            borderColor: state.isFocused ? focusColor : 'transparent',
            borderRadius: 'var(--chakra-radii-lg)',
            ...(state.isFocused && { boxShadow: `0 0 0 1px ${focusColor}` }),
            '&:hover': {
              borderColor: state.isFocused ? focusColor : 'transparent'
            },
            ...control?.(baseStyles, state)
          }
        },
        menu: (baseStyles, state) => ({
          ...baseStyles,
          borderRadius: 'var(--chakra-radii-lg)',
          overflow: 'hidden',
          zIndex: 'var(--chakra-zIndices-dropdown)',
          ...menu?.(baseStyles, state)
        }),
        valueContainer: (baseStyles) => ({
          ...baseStyles,
          paddingLeft: 'var(--chakra-space-4)'
        }),
        multiValue: (baseStyles, state) => ({
          ...baseStyles,
          borderRadius: 'var(--chakra-radii-xl)',
          ...multiValue?.(baseStyles, state)
        }),
        multiValueRemove: (baseStyles, state) => ({
          ...baseStyles,
          borderRadius: '0 var(--chakra-radii-lg) var(--chakra-radii-lg) 0',
          ...multiValueRemove?.(baseStyles, state)
        }),
        multiValueLabel: (baseStyles, state) => ({
          ...baseStyles,
          paddingLeft: '8px',
          ...multiValueLabel?.(baseStyles, state)
        }),
        ...restStyles
      }}
      {...props}
    />
  )
}

export const GrandSelect = forwardRef(_GrandSelect) as <
  Option = unknown,
  IsMulti extends boolean = false
>(
  props: GrandSelectProps<Option, IsMulti> & {
    ref?: ForwardedRef<SelectComponentType<Option, IsMulti>>
  }
) => ReturnType<typeof _GrandSelect>
