import type { FC } from 'react'
import type { BoxProps } from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'
import { clsx } from 'clsx'

type ITopNavSpacer = BoxProps

const TopNavSpacer: FC<ITopNavSpacer> = ({ className, ...props }) => {
  return <Box className={clsx('top-nav-spacer', className)} w="100%" {...props} />
}

export default TopNavSpacer
