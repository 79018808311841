'use client'
import type { Context, Reducer } from 'react'
import { useCallback, useReducer } from 'react'
import type {
  SlideoutContextProps,
  SlideOutContextType,
  SlideOutPayloadProps,
  SlideoutSize,
  SlideOutState
} from './types'

interface SlideOutProviderProps<C, F> extends SlideoutContextProps {
  context: Context<SlideOutContextType<C, F> | null>
}

const reducer = <C, F, E>(
  state: SlideOutState<C, F, E>,
  newState: Partial<SlideOutState<C, F, E>>
) => {
  return { ...state, ...newState }
}

const initialState: SlideOutState<null, null, null> = {
  isHidden: true,
  size: 'lg',
  title: '',
  componentId: null,
  footerId: null,
  extraArgs: null,
  drawerProps: null,
  shouldUnmountComponentAfterClose: false,
  shouldCloseAfterRouterChanged: true
}

const SlideOutProvider = <C, F>({ children, context }: SlideOutProviderProps<C, F>) => {
  const [state, dispatch] = useReducer<Reducer<SlideOutState<C | null, F | null, any>, any>>(
    reducer,
    initialState
  )

  const setSlideOutOpen = useCallback((): void => {
    dispatch({ isHidden: false })
  }, [])

  const setSlideOutClose = useCallback((): void => {
    dispatch({ isHidden: true })
  }, [])

  const setSlideOutChildren = useCallback(<E,>(payload: SlideOutPayloadProps<C, F, E>): void => {
    const {
      componentId,
      title,
      footerId,
      shouldUnmountComponentAfterClose,
      shouldCloseAfterRouterChanged = true,
      extraArgs,
      drawerProps,
      overlayProps
    } = payload
    dispatch({
      componentId,
      title,
      footerId,
      shouldUnmountComponentAfterClose,
      shouldCloseAfterRouterChanged,
      extraArgs,
      drawerProps,
      overlayProps
    })
  }, [])

  const setSlideOutSize = useCallback((payload: SlideoutSize): void => {
    dispatch({ size: payload })
  }, [])

  return (
    <context.Provider
      value={{
        ...state,
        setSlideOutOpen,
        setSlideOutClose,
        setSlideOutChildren,
        setSlideOutSize
      }}>
      {children}
    </context.Provider>
  )
}

export default SlideOutProvider
