'use client'
import type { ReactNode } from 'react'
import { useCallback, useState } from 'react'
import { debounce } from 'shared-utils'
import useIsomorphicLayoutEffect from '../../hooks/useIsomorphicLayoutEffect'

function getDimensionObject(node: any) {
  const rect = node.getBoundingClientRect()
  return {
    width: rect.width,
    height: rect.height,
    top: rect.top,
    left: rect.left,
    x: rect.x,
    y: rect.y,
    right: rect.right,
    bottom: rect.bottom
  }
}

export default function useBoundingRect(
  limit?: any
): [any, { width: number; height: number } | Record<string, never>, any] {
  const [dimensions, setDimensions] = useState<
    { width: number; height: number } | Record<string, never>
  >({})
  const [node, setNode] = useState<ReactNode | null>(null)

  const ref = useCallback((n: ReactNode) => {
    setNode(n)
  }, [])

  useIsomorphicLayoutEffect(() => {
    if (typeof window !== 'undefined' && node) {
      const measure = () =>
        window.requestAnimationFrame(() => {
          setDimensions(getDimensionObject(node))
        })

      measure()

      // for the first render it measures wrong and slide items shifted to the right
      setTimeout(() => {
        measure()
      }, 300)

      const listener = debounce(limit ? limit : 100, measure)

      window.addEventListener('resize', listener)
      window.addEventListener('scroll', listener)
      return () => {
        window.removeEventListener('resize', listener)
        window.removeEventListener('scroll', listener)
      }
    }
    return () => undefined
  }, [node, limit])

  // useEffect(() => {
  //   if (typeof window !== 'undefined' && node) {
  //     setTimeout(() => {
  //       const measure = () =>
  //         window.requestAnimationFrame(() => {
  //           const dim = getDimensionObject(node);
  //           console.log('useEffect dim', dim);
  //           setDimensions(dim)
  //         })
  //
  //       measure()
  //     }, 500);
  //   }
  // }, [node, limit])

  return [ref, dimensions, node]
}
