import type { FC } from 'react'
import type { StackProps } from '@chakra-ui/react'
import { Stack, Text } from '@chakra-ui/react'

interface IGrandRibbon extends StackProps {
  ribbonText: string
}

const GrandRibbon: FC<IGrandRibbon> = ({ ribbonText, ...props }) => {
  return (
    <Stack
      _before={{
        position: 'absolute',
        content: '""',
        zIndex: -2,
        display: 'block',
        borderWidth: '8px',
        borderColor: 'primary',
        borderTopColor: 'transparent',
        borderRightColor: 'transparent',
        top: -2,
        right: '6.5%'
      }}
      bg="transparent"
      className="grand-ribbon"
      h={250}
      left={0}
      overflow="hidden"
      position="absolute"
      top={-2}
      w={props.w ? props.w : 250}
      zIndex={4}
      {...props}>
      <Stack
        alignItems="center"
        as="span"
        background="primary"
        boxShadow="md"
        color="white"
        display="block"
        fontWeight="medium"
        left="-35%"
        p="10px"
        position="absolute"
        textAlign="center"
        top="35%"
        transform="rotate(-45deg)"
        w={350}>
        <Text ml={4}>{ribbonText}</Text>
      </Stack>
    </Stack>
  )
}

export default GrandRibbon
