'use client'
import type { Context, ReactNode } from 'react'
import { useEffect, useRef, useState } from 'react'
import type { FlexProps } from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'
import HoverableComponent from '../../common/HoverableComponent'
import TopNavSpacer from '../../storefront/layout/TopNavSpacer'
import { useContextSlideOut } from './useContextSlideOut'
import type { SlideOutContextType, SlideoutContextProps } from './types'

export interface SlideOutStoreControllerProps<C, F> extends SlideoutContextProps {
  footer: ReactNode
  context: Context<SlideOutContextType<C, F> | null>
  topGap?: FlexProps['h']
}

const SlideOutStoreController = <C, F>({
  children,
  footer,
  topGap,
  context
}: SlideOutStoreControllerProps<C, F>) => {
  const { isHidden, closeSlideOut, drawerProps, overlayProps, shouldUnmountComponentAfterClose } =
    useContextSlideOut(context)
  const [isInsideBody, setIsInsideBody] = useState(false)

  useEffect(() => {
    if (!isInsideBody) {
      closeSlideOut()
    }
  }, [isInsideBody, closeSlideOut])

  useEffect(() => {
    function handleEscapeKey(event: KeyboardEvent) {
      if (event.code === 'Escape' && !isHidden) {
        closeSlideOut()
      }
    }

    document.addEventListener('keydown', handleEscapeKey)
    return () => {
      document.removeEventListener('keydown', handleEscapeKey)
    }
  }, [closeSlideOut, isHidden])

  const bodyRef = useRef<HTMLBodyElement | null>(null)

  useEffect(() => {
    const updatePageScroll = () => {
      bodyRef.current = window.document.querySelector('body')

      if (bodyRef.current) {
        if (!isHidden) {
          bodyRef.current.style.overflow = 'hidden'
        } else {
          bodyRef.current.style.overflow = ''
        }
      }
    }

    updatePageScroll()
  }, [isHidden])

  const placement = (() => {
    if (drawerProps && 'placement' in drawerProps) {
      switch (drawerProps.placement) {
        case 'top':
          return {
            h: 'auto',
            w: 'full',
            top: 0,
            right: 0,
            left: 0,
            bottom: 'auto',
            transform: 'translate3d(0, -100%, 0)'
          }
        case 'bottom':
          return {
            h: 'auto',
            w: 'full',
            top: 'auto',
            left: 0,
            right: 0,
            bottom: 0,
            transform: 'translate3d(0, 100%, 0)'
          }
        case 'right':
          return {
            h: 'full',
            w: {
              base: 'full',
              sm: 'xl'
            },
            top: 0,
            bottom: 0,
            right: 0,
            left: 'auto'
          }
        case 'left':
          return {
            h: 'full',
            w: {
              base: 'full',
              sm: 'xl'
            },
            top: 0,
            bottom: 0,
            right: 'auto',
            left: 0,
            transform: 'translate3d(-100%, 0, 0)'
          }
        case undefined: {
          throw new Error('Not implemented yet: undefined case')
        }
        case 'start': {
          throw new Error('Not implemented yet: "start" case')
        }
        case 'end': {
          throw new Error('Not implemented yet: "end" case')
        }
      }
    }
    return {
      h: 'auto',
      w: 'full',
      top: 'auto',
      bottom: 'auto',
      right: 'auto',
      left: 'auto'
    }
  })()

  return (
    <Box id="slide-out" position="absolute">
      {!isHidden && (
        <Box
          backdropFilter="blur(2px)"
          bg="blackAlpha.600"
          h="100%"
          id="slide-out-overlay"
          position="fixed"
          w="100%"
          zIndex={9}
          {...overlayProps}
        />
      )}

      <Box
        bg="background.default"
        borderBottomLeftRadius="md"
        borderBottomRightRadius="md"
        display={!isHidden ? 'block' : 'none'}
        id="slide-out-content"
        position="fixed"
        px={4}
        shadow="md"
        zIndex={10}
        {...placement}
        {...drawerProps}>
        <HoverableComponent
          className="slide-out__content-container"
          cursor="default"
          display="flex"
          flexDirection="column"
          onHover={setIsInsideBody}
          py={6}>
          {topGap ? <TopNavSpacer minH={topGap} /> : null}
          <Box className="slide-out__content-body" overflowY="auto" px={1}>
            {shouldUnmountComponentAfterClose && isHidden ? null : children}
          </Box>
          <Box className="slide-out__content-footer">{footer}</Box>
        </HoverableComponent>
      </Box>
    </Box>
  )
}

export default SlideOutStoreController
