import { useContext } from 'react'
import { UIGlobalsContext } from '../context/UIGlobalsContext'

export const useUIGlobalsContext = () => {
  const ctx = useContext(UIGlobalsContext)

  if (!ctx) {
    throw Error(
      'No context provider was found. Wrap your app with UIGlobalsContextProvider to use this component.'
    )
  }

  return { ...ctx }
}
