import type { FC, ReactElement } from 'react'
import type { AccordionProps } from '@chakra-ui/react'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box
} from '@chakra-ui/react'
import GrandHeading from './GrandHeading'

interface IGrandAccordionProps extends AccordionProps {
  items: IAccordionItem[]
  buttonProps?: any
  panelProps?: any
}

export interface IAccordionItem {
  id: string
  title: string
  component: ReactElement | null
  onClick?: () => void
  noArrow?: boolean
  buttonProps?: any
}

const GrandAccordion: FC<IGrandAccordionProps> = ({
  items,
  buttonProps = null,
  panelProps = null,
  ...props
}) => {
  return (
    <Accordion {...props}>
      {items.map((item) => (
        <AccordionItem key={item.id}>
          <AccordionButton
            _focus={{
              outlineColor: 'none'
            }}
            aria-label={item.title}
            fontSize={{
              base: 'sm',
              md: 'lg'
            }}
            onClick={item.onClick}
            py={{
              base: 0,
              md: 'initial'
            }}
            {...item.buttonProps}
            {...buttonProps}>
            <Box flex="1" textAlign="left">
              <GrandHeading
                color="inherit"
                cursor="pointer"
                fontSize="inherit"
                fontWeight="inherit"
                headingTag="h2"
                title={item.title}
              />
            </Box>
            {!item.noArrow ? <AccordionIcon /> : null}
          </AccordionButton>
          <AccordionPanel pb={4} {...panelProps}>
            {item.component}
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  )
}

export default GrandAccordion
