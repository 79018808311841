import type { ElementType } from 'react'

interface Props {
  isChecked?: boolean
  isIndeterminate?: boolean
  as: ElementType
}

// https://github.com/chakra-ui/chakra-ui/issues/7044
// eslint-disable-next-line @typescript-eslint/no-unused-vars -- Workaround ChakraUI
const CheckboxIcon = ({ isChecked, isIndeterminate, as: Element, ...rest }: Props) => {
  return <Element {...rest} />
}

export default CheckboxIcon
