import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react'
import type { Context } from 'react'
import { useContextModal } from './useContextModal'
import type { ModalContextProps, ModalContextType } from './types'

export interface ModalBasicControllerProps<C> extends ModalContextProps {
  context: Context<ModalContextType<C> | null>
}

const ModalBasicController = <C,>({ context, children }: ModalBasicControllerProps<C>) => {
  const { closeModal, isHidden, size, title, hideCloseButton, styles } = useContextModal(context)

  return (
    <Modal
      isCentered
      isOpen={!isHidden}
      motionPreset="scale"
      onClose={closeModal}
      scrollBehavior="inside"
      {...{ size }}>
      <ModalOverlay />
      <ModalContent borderColor="primary" borderWidth="1px" {...styles?.content}>
        {Boolean(title) && (
          <ModalHeader fontSize="sm" textAlign="center">
            {title}
          </ModalHeader>
        )}
        {!hideCloseButton && (
          <ModalCloseButton _focus={{ outline: 'none' }} aria-label="close" zIndex={1} />
        )}
        <ModalBody {...styles?.body}>{children}</ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ModalBasicController
