import type { MouseEvent, RefAttributes } from 'react'
import { useCallback, useEffect, useState, useRef, useMemo } from 'react'
import type { BoxProps } from '@chakra-ui/react'
import type { HTMLChakraProps } from '@chakra-ui/system'

interface UseZoomParams {
  zoom?: number
  resetInResizeWindow?: boolean
}

interface ZoomShift {
  left: string
  top: string
}

type ContainerAttrs = Pick<BoxProps, 'onClick' | 'cursor' | 'overflow' | 'onPointerMove'> &
  RefAttributes<HTMLDivElement>

type TargetAttrs = Partial<Pick<HTMLChakraProps<'div'>, 'width' | 'height' | 'transform'>>

const useZoom = ({ zoom = 2, resetInResizeWindow = false }: UseZoomParams | undefined = {}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [isZoomed, setIsZoomed] = useState(false)
  const [zoomShift, setZoomShift] = useState<ZoomShift>({ left: '0%', top: '0%' })
  const [wrapperRect, setWrapperRect] = useState<DOMRect | null>(null)
  const onPointerMove = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (!containerRef.current) {
        return
      }

      let rect = wrapperRect

      if (!rect) {
        rect = containerRef.current.getBoundingClientRect()
        setWrapperRect(rect)
      }

      const left = Math.max(((e.clientX - rect.left) / rect.width) * 100, 0)
      const top = Math.max(((e.clientY - rect.top) / rect.height) * 100, 0)

      setZoomShift({ left: `${left / zoom - left}%`, top: `${top / zoom - top}%` })
    },
    [wrapperRect, zoom]
  )

  useEffect(() => {
    if (containerRef.current) {
      setWrapperRect(containerRef.current.getBoundingClientRect())
    }

    const fn = () => {
      if (resetInResizeWindow) {
        setIsZoomed(false)
      }

      if (containerRef.current) {
        setWrapperRect(containerRef.current.getBoundingClientRect())
      }
    }
    window.addEventListener('resize', fn)

    return () => {
      window.removeEventListener('resize', fn)
    }
  }, [resetInResizeWindow])

  const toggleIsZoomed = useCallback(() => {
    setIsZoomed((prevState) => !prevState)
  }, [])

  const containerAttrs: ContainerAttrs = useMemo(
    () => ({
      onClick: () => {
        toggleIsZoomed()
      },
      cursor: isZoomed ? 'zoom-out' : 'zoom-in',
      overflow: 'hidden',
      onPointerMove,
      ref: containerRef
    }),
    [isZoomed, onPointerMove, toggleIsZoomed]
  )

  const defaultTargetAttrs = useMemo(
    () => ({
      width: '100%',
      height: '100%'
    }),
    []
  )

  const targetAttrs: TargetAttrs = useMemo(
    () =>
      isZoomed
        ? {
            width: `${100 * zoom}%`,
            height: `${100 * zoom}%`,
            transform: `translate3d(${zoomShift.left}, ${zoomShift.top}, 0)`
          }
        : defaultTargetAttrs,
    [defaultTargetAttrs, isZoomed, zoom, zoomShift.left, zoomShift.top]
  )

  return {
    isZoomed,
    setIsZoomed,
    toggleIsZoomed,
    containerAttrs,
    targetAttrs,
    zoomShift,
    containerRef
  }
}

export default useZoom
