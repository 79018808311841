import { forwardRef, useCallback } from 'react'
import type { BoxProps } from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'

export type HoverableComponentProps = BoxProps & {
  onHover: (isInSide: boolean) => void
}

const HoverableComponent = forwardRef<HTMLDivElement, HoverableComponentProps>(
  ({ onHover, ...props }, ref) => {
    const handleMouseOver = useCallback(() => {
      onHover(true)
    }, [onHover])

    const handleMouseOut = useCallback(() => {
      onHover(false)
    }, [onHover])

    return (
      <Box
        {...{ ref }}
        cursor="pointer"
        h="100%"
        onMouseLeave={handleMouseOut}
        onMouseOver={handleMouseOver}
        w="100%"
        {...props}
      />
    )
  }
)

HoverableComponent.displayName = 'HoverableComponent'

export default HoverableComponent
