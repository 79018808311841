'use client'
import type { ReactNode } from 'react'
import { createContext } from 'react'
import type { UIGlobalsContextType } from './types'

export const UIGlobalsContext = createContext<UIGlobalsContextType | null>(null)

interface UIGlobalsContextProps {
  children: ReactNode
  providerProps: UIGlobalsContextType
}

function UIGlobalsContextProvider({ children, providerProps }: UIGlobalsContextProps) {
  return (
    <UIGlobalsContext.Provider value={{ ...providerProps }}>{children}</UIGlobalsContext.Provider>
  )
}

export default UIGlobalsContextProvider
