import type { TextProps } from '@chakra-ui/react'
import { Box, Text } from '@chakra-ui/react'
import { forwardRef } from 'react'

export type GrandTextProps = TextProps

const GrandText = forwardRef<HTMLParagraphElement, GrandTextProps>((props, ref) => {
  return <Box as={Text} cursor="inherit" w="100%" {...{ ref }} {...props} />
})

GrandText.displayName = 'GrandText'

export default GrandText
