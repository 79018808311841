import type { ChangeEvent } from 'react'
import { useState } from 'react'

const useCheckboxes = (initial: string[] = []) => {
  const [selected, setSelected] = useState<string[]>(initial)

  const handleStringCheckBoxes = (event: ChangeEvent<HTMLInputElement>) => {
    const checkboxName = event.target.name
    const isChecked = event.target.checked

    if (selected.includes(checkboxName)) {
      if (!isChecked) {
        setSelected((prevState) => [...prevState.filter((e) => e !== checkboxName)])
      } else {
        setSelected((prevState) => [...prevState, checkboxName])
      }
    } else {
      setSelected((prevState) => [...prevState, checkboxName])
    }
  }

  return {
    handleStringCheckBoxes,
    selected
  }
}

export default useCheckboxes
