import type { FC } from 'react'
import type { BreadcrumbProps } from '@chakra-ui/react'
import { Breadcrumb, BreadcrumbItem, Text } from '@chakra-ui/react'
import { BiChevronRight } from 'react-icons/bi'
import GrandNextLink from './GrandNextLink'

interface IGrandBreadcrumbProps extends BreadcrumbProps {
  breadCrumbs: BreadCrumbItem[]
}

export interface BreadCrumbItem {
  label: string
  path: string
  isCurrent?: boolean
}

const GrandBreadcrumb: FC<IGrandBreadcrumbProps> = ({ breadCrumbs, ...props }) => {
  return (
    <Breadcrumb separator={<BiChevronRight color="text.mild" />} spacing={1} {...props}>
      {breadCrumbs.map((breadcrumb: BreadCrumbItem) => (
        <BreadcrumbItem key={breadcrumb.label}>
          <GrandNextLink aria-label={breadcrumb.label} href={breadcrumb.path}>
            <Text
              cursor="pointer"
              fontSize="xs"
              fontWeight="bold"
              {...(!breadcrumb.isCurrent && { color: 'text.mild' })}>
              {breadcrumb.label}
            </Text>
          </GrandNextLink>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  )
}

export default GrandBreadcrumb
