import { FaCity, FaCrown, FaRegStar } from 'react-icons/fa6'
import { FaShippingFast } from 'react-icons/fa'
import { MdOutlineLocalShipping } from 'react-icons/md'
import { CiGlobe } from 'react-icons/ci'
import { GrCatalog } from 'react-icons/gr'
import { type IconName } from 'ecosystem'
import { type IconBaseProps, type IconType } from 'react-icons'
import { useMemo } from 'react'

export const UIIcons: Record<IconName, IconType> = {
  FaCity,
  FaCrown,
  FaShippingFast,
  FaRegStar,
  MdOutlineLocalShipping,
  CiGlobe,
  GrCatalog
}

export const useUIIcon = (iconName?: IconName) => {
  return useMemo(
    () => (iconName ? UIIcons[iconName] : (_iconBaseProps: IconBaseProps) => <>{false}</>),
    [iconName]
  )
}
