import type { FC, ReactElement } from 'react'
import { Box, Button, Flex, HStack, Image, Link, VStack } from '@chakra-ui/react'
import NextLink from 'next/link'
import { openInNewTab } from 'shared-utils'
import type { GrandIconButtonProps } from './GrandIconButton'
import GrandIconButton from './GrandIconButton'
import ParsedHtml from './ParsedHtml'
import GrandHeading from './GrandHeading'
import GrandText from './GrandText'

interface CTA {
  label: string
  path: string
  isExternal?: boolean
}

export interface IGrandImageAndTextProps {
  reverse?: boolean
  imgUrl?: string
  title?: string
  text?: string
  content?: ReactElement | null
  cta?: CTA
  imageButtons?: GrandIconButtonProps[]
  styleProps?: GrandImageAndTextStyleProps
}

export interface GrandImageAndTextStyleProps {
  wrapper?: any
  textWrapper?: any
  text?: any
  title?: any
  image?: any
}

const GrandImageAndText: FC<IGrandImageAndTextProps> = ({
  reverse,
  imgUrl,
  title,
  text,
  content = null,
  cta = null,
  imageButtons,
  styleProps = {
    wrapper: {},
    textWrapper: {},
    text: {},
    title: {},
    image: {}
  }
}) => {
  return (
    <Flex
      flexDirection={{
        base: 'column',
        md: reverse ? 'row-reverse' : 'row'
      }}
      gap="4"
      w="100%"
      {...styleProps.wrapper}>
      <Box position="relative" w={['100%', '100%', '50%']}>
        {imgUrl ? <Image alt={title || ''} src={imgUrl} {...styleProps.image} /> : null}
        {imageButtons?.length ? (
          <HStack bottom={10} position="absolute" right={10} spacing={2}>
            {imageButtons.map((button) => (
              <GrandIconButton key={`${button.id}`} {...button} minW={undefined} size="sm" />
            ))}
          </HStack>
        ) : null}
      </Box>
      <VStack
        justifyContent="center"
        p={4}
        spacing={4}
        textAlign="center"
        w={['100%', '100%', '50%']}
        {...styleProps.textWrapper}>
        {title ? (
          <GrandHeading
            fontSize={{ base: '14px', sm: '18px', md: '24px' }}
            headingTag="h2"
            title={title}
            {...styleProps.title}
          />
        ) : null}
        {text ? (
          <GrandText fontSize={{ base: '10px', sm: 'xs', md: 'sm', lg: 'md' }} {...styleProps.text}>
            <ParsedHtml html={text} />
          </GrandText>
        ) : null}
        {content}
        <CTAComponent {...{ cta }} />
      </VStack>
    </Flex>
  )
}

export default GrandImageAndText

const CTAComponent: FC<{ cta?: CTA | null }> = ({ cta }) => {
  if (!cta) return null

  return (
    <>
      cta.isExternal ? (
      <Link
        _hover={{
          bg: 'primary',
          color: 'text.light'
        }}
        aria-label={cta.label}
        as={Button}
        bg="none"
        borderColor="white"
        borderWidth="1px"
        onClick={() => {
          openInNewTab(cta.path || '')
        }}
        size="md"
        variant="outline">
        {cta.label}
      </Link>
      ) : (
      <Link as={NextLink} href={cta.path}>
        <Button
          _hover={{
            bg: 'primary',
            color: 'text.light'
          }}
          aria-label={cta.label}
          bg="none"
          borderColor="white"
          borderWidth="1px"
          variant="outline">
          {cta.label}
        </Button>
      </Link>
      )
    </>
  )
}
