'use client'
import { Button, Icon, Image, Menu, MenuButton, MenuList } from '@chakra-ui/react'
import { type FC, Fragment, useState } from 'react'
import { BiChevronDown } from 'react-icons/bi'
import { type Language } from 'ecosystem'

interface Lang {
  locale: Language
  label: string
  flagSrc: string
}

interface LangSelectorProps {
  languages: Lang[]
  defaultLocale: Language
  linkRenderer: (props: {
    locale: Language
    label: string
    flagSrc: string
    setLanguage: (language: Lang) => void
  }) => JSX.Element
}

const LangSelector: FC<LangSelectorProps> = ({ languages, defaultLocale, linkRenderer }) => {
  const defaultLang = languages.find((lang) => lang.locale === defaultLocale)
  const [selectedLanguage, setSelectedLanguage] = useState<Lang>(
    defaultLang ? defaultLang : { locale: defaultLocale, label: '', flagSrc: '' }
  )

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            size="sm"
            as={Button}
            variant="outline"
            color={isOpen ? 'gray.600' : 'gray.300'}
            borderColor={isOpen ? 'gray.400' : 'border'}
            p={{ base: 0.5, md: 1 }}
            rounded="sm"
            rightIcon={<Icon as={BiChevronDown} boxSize={6} />}>
            <Image
              alt={selectedLanguage.label || defaultLocale}
              rounded="full"
              src={selectedLanguage.flagSrc}
              aria-label={selectedLanguage.label}
              w={22}
              h={22}
              minW={22}
              maxW={22}
              objectFit="cover"
            />
          </MenuButton>
          <MenuList>
            {languages.map(({ locale, label, flagSrc }) => (
              <Fragment key={locale}>
                {linkRenderer({
                  locale,
                  label,
                  flagSrc,
                  setLanguage: setSelectedLanguage
                })}
              </Fragment>
            ))}
          </MenuList>
        </>
      )}
    </Menu>
  )
}

export default LangSelector
