'use client'
import type { ReactNode } from 'react'
import { useOverridesContext } from '../hooks'
import { OverridesContext } from '../context/OverridesContext'
import type { OverridesContextType } from '../context'

interface OverridesContextProviderProps {
  children: ReactNode | ReactNode[]
  overrides: OverridesContextType
}

function OverridesContextProvider({ children, overrides }: OverridesContextProviderProps) {
  const parentOverrides = useOverridesContext()
  return (
    <OverridesContext.Provider value={{ ...parentOverrides, ...overrides }}>
      {children}
    </OverridesContext.Provider>
  )
}

export default OverridesContextProvider
