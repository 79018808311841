'use client'
import { createContext } from 'react'
import type { ModalContextType } from './types'

export const createModalContext = <C,>() => {
  return createContext<ModalContextType<C> | null>(null)
}

export function ModalContextProvider<C>() {
  const ModalContext = createModalContext<C>()

  return {
    ModalContext
  }
}
