'use client'
import type { ReactElement } from 'react'
import { forwardRef, useState, useCallback } from 'react'
import type { ImageProps } from 'next/image'
import Image from 'next/image'
import type { BoxProps, FlexProps } from '@chakra-ui/react'
import { Box, Flex, useBreakpointValue, useUpdateEffect } from '@chakra-ui/react'
import { AiOutlineZoomIn } from 'react-icons/ai'
import { clsx } from 'clsx'
import { useUIGlobalsContext } from '../lib/ui-globals/hooks'
import { useOverrideComponentPropsContext } from '../lib/override-component-props'

export interface IImageBoxProps extends FlexProps {
  imageProps: Partial<ImageProps>
  containerProps?: BoxProps
  withZoomIcon?: boolean
  badges?: ReactElement
}

const ImageBox = forwardRef<HTMLDivElement, IImageBoxProps>((_props, ref) => {
  const mergedProps = useOverrideComponentPropsContext('ImageBox', _props)
  const { withZoomIcon, imageProps, badges, containerProps, className, ...props } = mergedProps
  const { src, alt, title, style, ...otherImageProps } = imageProps
  const zoomIconSize = useBreakpointValue({ base: 22, md: 26 })
  const { defaultImgSrc } = useUIGlobalsContext()
  const [imgSrc, setImgSrc] = useState(src || defaultImgSrc)

  useUpdateEffect(() => {
    setImgSrc(src || defaultImgSrc)
  }, [src])

  const onError = useCallback(() => {
    setImgSrc(defaultImgSrc)
  }, [defaultImgSrc])

  return (
    <Flex borderRadius="sm" className={clsx('image-box', className)} h={200} ref={ref} {...props}>
      <Box
        borderRadius="sm"
        h="100%"
        overflow="hidden"
        position="relative"
        w="100%"
        {...containerProps}>
        <Image
          alt={alt || ''}
          fill
          onError={onError}
          src={imgSrc}
          style={{
            objectFit: 'cover',
            ...style
          }}
          title={title || ''}
          {...otherImageProps}
        />
        {Boolean(badges) && (
          <Box left="6%" position="absolute" top="6%" w="auto" zIndex={2}>
            {badges}
          </Box>
        )}

        {withZoomIcon ? (
          <Box position="absolute" right="6%" top="6%">
            <AiOutlineZoomIn color="primary" size={zoomIconSize} />
          </Box>
        ) : null}
      </Box>
    </Flex>
  )
})

ImageBox.displayName = 'ImageBox'

export default ImageBox
