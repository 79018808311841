import type { SwitchProps, StackProps, FormLabelProps } from '@chakra-ui/react'
import { Switch, Stack, FormLabel } from '@chakra-ui/react'
import type { FC } from 'react'
import { forwardRef } from 'react'

interface GrandSwitchProps extends SwitchProps {
  id: string
  label: string
  labelProps?: FormLabelProps
  wrapperProps?: StackProps
}

const GrandSwitch: FC<GrandSwitchProps> = forwardRef<HTMLInputElement, GrandSwitchProps>(
  ({ id, label, labelProps = {}, wrapperProps = {}, ...props }, ref) => {
    return (
      <Stack align="center" direction="row" spacing={2} {...wrapperProps}>
        <FormLabel htmlFor={id} m={0} {...labelProps}>
          {label}
        </FormLabel>
        <Switch {...{ id, ref }} {...props} />
      </Stack>
    )
  }
)

GrandSwitch.displayName = 'GrandSwitch'

export default GrandSwitch
