import type { FC } from 'react'
import styled from '@emotion/styled'
import type { BoxProps } from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'
import ParsedHtml from './ParsedHtml'

const ArticleStyles = styled(Box)`
  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 32px;
  }

  h3 {
    font-size: 28px;
  }

  h4 {
    font-size: 24px;
  }

  h5 {
    font-size: 20px;
  }

  h6 {
    font-size: 18px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a {
    font-weight: bold;
  }

  .ql-align-center {
    text-align: center;
  }

  .ql-align-left {
    text-align: left;
  }

  .ql-align-right {
    text-align: right;
  }
`

export interface HTMLArticleProps extends BoxProps {
  content: string
}

export const HTMLArticle: FC<HTMLArticleProps> = ({ content, ...props }) => {
  return (
    <ArticleStyles sx={{ a: { color: 'primary' } }} {...props}>
      <ParsedHtml html={content} />
    </ArticleStyles>
  )
}
