import type { FC } from 'react'
import type { BoxProps } from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'
import type { ImageProps } from 'next/image'
import Image from 'next/image'

interface IResponsiveImageProps extends BoxProps {
  src: string
  alt: string
  imageProps?: Partial<ImageProps>
}

const ResponsiveImage: FC<IResponsiveImageProps> = ({ src, alt, imageProps, ...props }) => {
  const { style, ...otherProps } = imageProps || {}
  return (
    <Box borderRadius="sm" flexGrow={1} h={400} overflow="hidden" position="relative" {...props}>
      <Image
        fill
        style={{
          objectFit: 'cover',
          ...style
        }}
        {...{ src, alt }}
        {...otherProps}
      />
    </Box>
  )
}

export default ResponsiveImage
