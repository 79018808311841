import { useEffect, useRef } from 'react'

function useDidUpdateEffect(fn: () => void, inputs: any[]) {
  const didMountRef = useRef(false)

  useEffect(() => {
    if (didMountRef.current) {
      fn()
      return
    }
    didMountRef.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only on mount
  }, inputs)
}

export default useDidUpdateEffect
