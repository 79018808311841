'use client'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import sanitizeHtml from 'sanitize-html'

interface ParsedHtmlProps {
  html: string
}

const htmlSanitizerOptions = {
  //reference: https://www.npmjs.com/package/sanitize-html
  allowedTags: [
    'address',
    'article',
    'aside',
    'footer',
    'header',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'hgroup',
    'main',
    'nav',
    'section',
    'blockquote',
    'dd',
    'div',
    'dl',
    'dt',
    'figcaption',
    'figure',
    'hr',
    'li',
    'main',
    'ol',
    'p',
    'pre',
    'ul',
    'abbr',
    'b',
    'bdi',
    'bdo',
    'br',
    'cite',
    'code',
    'data',
    'dfn',
    'em',
    'i',
    'kbd',
    'mark',
    'q',
    'rb',
    'rp',
    'rt',
    'rtc',
    'ruby',
    's',
    'samp',
    'small',
    'span',
    'strong',
    'sub',
    'sup',
    'time',
    'u',
    'var',
    'wbr',
    'caption',
    'col',
    'colgroup',
    'table',
    'tbody',
    'td',
    'tfoot',
    'th',
    'thead',
    'tr',
    'a',
    'img'
  ],
  allowedClasses: {
    '*': ['ql-*']
  },
  allowedAttributes: {
    '*': ['style'],
    img: ['src', 'srcset', 'alt', 'title', 'width', 'height', 'loading'],
    a: ['href', 'rel', 'target']
  }
}

/** Sanitize an html string and return a React component */
const ParsedHtml: FC<ParsedHtmlProps> = ({ html }) => {
  const [sanitized, setSanitized] = useState<string>('')

  //prevent next hydration errors by injecting the html via the event loop
  useEffect(() => {
    setSanitized(sanitizeHtml(html, htmlSanitizerOptions))
  }, [html])

  return <span dangerouslySetInnerHTML={{ __html: sanitized }} />
}

export default ParsedHtml
