import type { BoxProps } from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'
import { clsx } from 'clsx'
import { useOverrideComponentPropsContext } from '../lib/override-component-props'

export type GrandOverlayProps = BoxProps

const GrandOverlay = (_props: GrandOverlayProps) => {
  const mergedProps = useOverrideComponentPropsContext('GrandOverlay', _props)
  const { className, ...props } = mergedProps

  return (
    <Box
      className={clsx('grand-overlay', className)}
      h="full"
      position="absolute"
      w="full"
      zIndex={1}
      {...props}
    />
  )
}

export default GrandOverlay
