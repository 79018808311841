'use client'
import type { BoxProps, StackProps } from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'
import type { FC } from 'react'
import { clsx } from 'clsx'
import { createCn } from 'shared-utils'
import type { IGrandHeadingProps } from '../../common'
import GrandHeading from '../../common/GrandHeading'
import { useOverrideComponentPropsContext } from '../../lib/override-component-props'
import ContentContainer from './ContentContainer'

export interface IGrandSection extends BoxProps {
  sectionId: string
  title?: string
  noWidthLimit?: boolean
  contentContainerProps?: StackProps
  titleProps?: Partial<IGrandHeadingProps>
  headingTag?: any
}

const cn = createCn('grand-section')

const GrandSection: FC<IGrandSection> = (_props: IGrandSection) => {
  const mergedProps = useOverrideComponentPropsContext('GrandSection', _props)

  const {
    sectionId,
    title,
    children,
    noWidthLimit,
    contentContainerProps: {
      className: contentContainerClassName = undefined,
      ...restContentContainerProps
    } = {},
    titleProps: {
      className: titleClassName = undefined,
      containerProps: { className: titleContainerClassName = '', ...titleContainerProps } = {},
      ...titleProps
    } = {},
    headingTag = 'h4',
    className,
    ...props
  } = mergedProps

  return (
    <Box
      as="section"
      className={clsx(cn(), className)}
      id={sectionId}
      py={[15, 30]}
      w="100%"
      {...props}>
      <ContentContainer
        className={clsx(cn('content-container'), contentContainerClassName)}
        direction="column"
        id={`${sectionId}-content-container`}
        spacing={10}
        {...restContentContainerProps}
        {...{ noWidthLimit }}>
        {title || titleProps.title ? (
          <GrandHeading
            className={clsx(cn('heading'), titleClassName)}
            containerProps={{
              className: clsx(cn('heading-container'), titleContainerClassName),
              w: 'auto',
              flexGrow: 0,
              alignSelf: 'flex-start',
              ...titleContainerProps
            }}
            headingTag={headingTag}
            mt={0}
            title={title || ''}
            withDecoration
            {...titleProps}
          />
        ) : null}
        {children}
      </ContentContainer>
      {/*<GrandDivider borderWidth={1} w={'95%'} mx="auto" display={{ base: 'block', md: 'none' }} />*/}
    </Box>
  )
}

export default GrandSection
