import type { ReactNode } from 'react'
import { useMemo } from 'react'
import type { BoxProps } from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'
import type { Ratio } from 'ecosystem'
import { useUIGlobalsContext } from '../lib/ui-globals/hooks'
import { useOverrideComponentPropsContext } from '../lib/override-component-props'

export type GrandAspectRatioProps<T = BoxProps, K extends keyof T = keyof T> = Pick<T, K> &
  BoxProps & {
    children: ReactNode
    breakpoints: {
      xs?: Ratio
      base: Ratio
      sm?: Ratio
      md?: Ratio
      lg?: Ratio
    }
    desktopItemHeightBreakpoint?: 'sm' | 'md' | 'lg'
  }

function GrandAspectRatio<T, K extends keyof T>(_props: GrandAspectRatioProps<T, K>) {
  const mergedProps = useOverrideComponentPropsContext('GrandAspectRatio', _props)
  const { breakpoints, children, desktopItemHeightBreakpoint = 'sm', ...props } = mergedProps
  const { mobileItemHeight, desktopItemHeight } = useUIGlobalsContext()
  const pb = useMemo(() => {
    const dict: Record<Ratio, string> = {
      '16:9': '56.25%',
      '4:3': '75%',
      '3:2': '66.66%',
      '8:5': '62.5%',
      '33:10': '30.3%',
      '1:1': '100%'
    }
    const o = Object.create(breakpoints)
    for (const k in breakpoints) {
      const key = breakpoints[k as 'base' | 'sm' | 'md']
      if (key) {
        o[k] = dict[key]
      }
    }
    return o
  }, [breakpoints])

  return (
    <Box
      h={{ base: mobileItemHeight, [desktopItemHeightBreakpoint]: desktopItemHeight }}
      position="relative"
      w="full"
      {...{ pb }}
      {...props}>
      <Box bottom={0} left={0} position="absolute" right={0} top={0}>
        {children}
      </Box>
    </Box>
  )
}

export default GrandAspectRatio
