import type { InputProps } from '@chakra-ui/react'
import { Input, InputGroup, InputRightAddon } from '@chakra-ui/react'
import { forwardRef } from 'react'
import { BiSearch } from 'react-icons/bi'

const SearchInput = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  return (
    <InputGroup borderColor="background.surface" borderWidth="1px" minW="20vw" size="sm" w="auto">
      <Input
        _focus={{
          borderColor: 'secondary'
        }}
        bg="background.default"
        borderBottomLeftRadius="sm"
        borderBottomRightRadius={0}
        borderTopLeftRadius="sm"
        borderTopRightRadius={0}
        {...{ ref }}
        {...props}
      />

      <InputRightAddon
        bg="background.default"
        borderBottomLeftRadius={0}
        borderBottomRightRadius="sm"
        borderTopLeftRadius={0}
        borderTopRightRadius="sm">
        <BiSearch />
      </InputRightAddon>
    </InputGroup>
  )
})

SearchInput.displayName = 'SearchInput'

export default SearchInput
