'use client'
import type { Context } from 'react'
import { useCallback, useContext } from 'react'
import type { ModalContextType, ModalPayloadProps, ModalState } from './types'

export interface UseSlideOutHookController<C> extends ModalState<C, unknown> {
  closeModal: () => void
}

export interface UseSlideOutHook<C> extends UseSlideOutHookController<C> {
  openModal: <E>(payload: ModalPayloadProps<C, E>) => void
}

export const useContextModal = <C>(
  context: Context<ModalContextType<C> | null>
): UseSlideOutHook<C> => {
  const ctx = useContext(context)

  if (!ctx) throw Error('No context found.')

  const { setModalChildren, setModalOpen, setModalClose, ...state } = ctx

  const closeModal = useCallback(() => {
    setModalClose()
  }, [setModalClose])

  const openModal = useCallback(
    <E>(payload: ModalPayloadProps<C, E>) => {
      setModalChildren(payload)
      setModalOpen()
    },
    [setModalChildren, setModalOpen]
  )

  return {
    closeModal,
    openModal,
    ...state
  }
}
