import type { FC, ReactElement } from 'react'
import type { FlexProps } from '@chakra-ui/react'
import { Box, Fade, Flex, useDisclosure } from '@chakra-ui/react'
import GrandText from './GrandText'

export interface IEmptyMessage extends FlexProps {
  text: string
  icon?: any
  extraComponent?: ReactElement
}

const EmptyMessage: FC<IEmptyMessage> = ({ text, icon, extraComponent, ...props }) => {
  const { isOpen } = useDisclosure({
    defaultIsOpen: true
  })

  return (
    <Flex
      alignItems="stretch"
      color="text.darkGrey"
      direction="column"
      justifyContent="center"
      ml="auto"
      mr="auto"
      textAlign="center"
      w="100%"
      {...props}>
      <Fade delay={0.2} in={isOpen}>
        {icon ? (
          <Flex alignItems="center" justifyContent="center" mb={4} w="100%">
            {icon}
          </Flex>
        ) : null}
        <GrandText fontSize="inherit">{text}</GrandText>

        {extraComponent ? <Box py={10}>{extraComponent}</Box> : null}
      </Fade>
    </Flex>
  )
}

export default EmptyMessage
