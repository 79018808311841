import type { FC } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { motion, useAnimation, useMotionValue } from 'framer-motion'
import type { StackProps } from '@chakra-ui/react'
import { Flex, VStack } from '@chakra-ui/react'
import { clsx } from 'clsx'
import { createCn } from 'shared-utils'

const MotionFlex = motion(Flex)

const cn = createCn('carousel-track')

const transitionProps = {
  stiffness: 400,
  type: 'spring',
  damping: 50,
  mass: 3
}

export type CarouselTrackProps = StackProps & {
  setTrackIsActive: any
  trackIsActive: any
  setActiveItem: any
  activeItem: any
  constraint: any
  multiplier: any
  itemWidth: any
  positions: any
}

const CarouselTrack: FC<CarouselTrackProps> = ({
  setTrackIsActive,
  trackIsActive,
  setActiveItem,
  activeItem,
  constraint,
  multiplier,
  itemWidth,
  positions,
  children,
  className,
  ...props
}) => {
  const [dragStartPosition, setDragStartPosition] = useState(0)
  const controls = useAnimation()
  const x = useMotionValue(0)
  const node = useRef(null)

  const handleDragStart = () => {
    setDragStartPosition(positions[activeItem])
  }

  // @ts-expect-error -- Expected error
  const handleDragEnd = (_, info) => {
    const distance = info.offset.x
    const velocity = info.velocity.x * multiplier
    const direction = velocity < 0 || distance < 0 ? 1 : -1

    const extrapolatedPosition =
      dragStartPosition +
      (direction === 1 ? Math.min(velocity, distance) : Math.max(velocity, distance))

    const closestPosition = positions.reduce((prev: any, curr: any) => {
      return Math.abs(curr - extrapolatedPosition) < Math.abs(prev - extrapolatedPosition)
        ? curr
        : prev
    }, 0)

    if (!(closestPosition < positions[positions.length - constraint])) {
      setActiveItem(positions.indexOf(closestPosition))
      void controls
        .start({
          x: closestPosition,
          transition: {
            velocity: info.velocity.x,
            ...transitionProps
          }
        })
        .then()
    } else {
      setActiveItem(positions.length - constraint)
      void controls
        .start({
          x: positions[positions.length - constraint],
          transition: {
            velocity: info.velocity.x,
            ...transitionProps
          }
        })
        .then()
    }
  }

  const handleResize = useCallback(
    (pos: Record<string, any>) =>
      controls.start({
        x: pos[activeItem],
        transition: {
          ...transitionProps
        }
      }),
    // eslint-disable-next-line -- Avoid extra renders
    [activeItem, controls, positions]
  )

  const handleClick = useCallback(
    (event: { target: any }) =>
      // @ts-expect-error -- Silence warning
      node.current?.contains(event.target) ? setTrackIsActive(true) : setTrackIsActive(false),
    [setTrackIsActive]
  )

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (trackIsActive) {
        if (activeItem < positions.length - constraint) {
          if (event.key === 'ArrowRight' || event.key === 'ArrowUp') {
            event.preventDefault()
            setActiveItem((prev: number) => prev + 1)
          }
        }
        if (activeItem > positions.length - positions.length) {
          if (event.key === 'ArrowLeft' || event.key === 'ArrowDown') {
            event.preventDefault()
            setActiveItem((prev: any) => prev - 1)
          }
        }
      }
    },
    [trackIsActive, setActiveItem, activeItem, constraint, positions.length]
  )

  useEffect(() => {
    void handleResize(positions)

    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('mousedown', handleClick)
    }
  }, [handleClick, handleResize, handleKeyDown, positions])

  if (!itemWidth) {
    return null
  }

  return (
    <VStack
      h="full"
      alignItems="stretch"
      className={clsx(cn(), className)}
      ref={node}
      spacing={5}
      {...props}>
      <MotionFlex
        _active={{ cursor: 'grabbing' }}
        animate={controls}
        cursor="grab"
        drag="x"
        dragConstraints={node}
        flexWrap="nowrap"
        minWidth="min-content"
        h="full"
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        style={{ x }}>
        {children}
      </MotionFlex>
    </VStack>
  )
}

export default CarouselTrack
