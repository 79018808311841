import type { Nullable, Option } from './general'
import type {
  PaymentProviderKlarnaOptions,
  PaymentProviderLedyerOptions,
  PaymentProviderName,
  PaymentProviderNexiOptions,
  PaymentProviderSveaOptions,
  SortingOption
} from './storefront'
import type { IngridCreateSessionRequest } from './ingrid'

export interface DbLogFields {
  created?: Option<number | string>
  updated?: Option<number | string>
}

export type DbInfoFields = DbLogFields & {
  id: string
  createdBy: Option<string>
  updatedBy: Option<string>
}

export interface MetaFields {
  metaTitle: Option<string>
  metaDescription: Option<string>
  metaHeader: Option<string>
}

export type CategoryDTO = {
  additionalText: Option<string>
  description: Option<string>
  id: string
  imgUrl: string
  name: string
  order: number
  slug: string
  subCategories?: Option<CategoryDTO[]>
  thumbnailDescription: string
  thumbnailUrl: string
} & MetaFields

export type FilterOptionTextValues = Option<string[]>
export type FilterOptionNumberValues = Option<number[]>

export interface FilterOptionDTO {
  label: Option<string>
  name: string
  numberValues: FilterOptionNumberValues
  textValues: FilterOptionTextValues
  variant: boolean
}

export interface ServiceDTO {
  id: string
  name: string
  description: string
  price: number
  discountPrice: number
}

export interface CheckoutService {
  quantity: number
  totalAmount: number
  service: ServiceDTO
}

export enum TagType {
  BADGE = 'BADGE',
  REGULAR = 'REGULAR'
}

export interface TagLightDTO {
  id: string
  imgUrl: string
  name: string
  slug: string
  type: TagType
}

export type TagDTO = {
  description: string
  thumbnailDescription: string
  thumbnailUrl: string
} & DbInfoFields &
  TagLightDTO &
  MetaFields

export interface ChannelDTO {
  code: string
  domain: string
  host: string
}

export interface DeliveryDTO {
  id: string
  name: string
  label: string
  buyable: boolean
}

export interface AssistantDTO {
  id: string
  company: string
  imgUrl: string
  name: string
}

export type EmbeddedAssistantSettings = Omit<AssistantDTO, 'id'>

export interface NavigationPath {
  label: string
  name: string
  path: string
}

export interface NavigationDTO {
  links: NavigationPath[]
  paths: NavigationPath[]
  showCart: boolean
}

export interface SearchDTO {
  priceBoundaries: number[]
  sortOptions: SortingOption[]
}

export interface SettingsDTO {
  assistant: Option<AssistantDTO>
  shipping?: {
    freeShippingOver?: Option<number>
    deliveryFee?: Option<number>
    freeDeliveryLabel?: Option<string>
    paidDeliveryLabel?: Option<string>
    providers?: {
      name: OrderDeliveryProviderName
      ingridOptions?: Option<{
        purchaseCountry?: IngridCreateSessionRequest['purchase_country']
        purchaseCurrency?: IngridCreateSessionRequest['purchase_currency']
      }>
    }[]
  }
  payment?: {
    providers?: {
      name: PaymentProviderName
      klarnaOptions?: Option<PaymentProviderKlarnaOptions>
      nexiOptions?: Option<PaymentProviderNexiOptions>
      sveaOptions?: Option<PaymentProviderSveaOptions>
      ledyerOptions?: Option<PaymentProviderLedyerOptions>
    }[]
  }
  layout: Record<string, any>
  logoUrl: Option<string>
  navigation: Option<NavigationDTO>
  search: Option<SearchDTO>
  theme: Record<string, any>
}

export enum StoreType {
  B2B = 'B2B',
  B2C = 'B2C',
  MARKETPLACE = 'MARKETPLACE'
}

export interface StoreDTO {
  brands: Option<BrandDTO[]>
  categories: Option<CategoryDTO[]>
  channel: ChannelDTO
  companyId: string
  deliveries: Option<DeliveryDTO[]>
  id: string
  services: Option<ServiceDTO[]>
  settings: Option<SettingsDTO>
  tags: Option<TagDTO[]>
  type: StoreType
}

export enum RelatedProductTypes {
  STANDARD = 'STANDARD',
  CUSTOM = 'CUSTOM'
}

export interface RelatedProductDTO {
  customRelationType: string
  relatedProductId: string
  type: RelatedProductTypes
}

export enum ProductMediaType {
  DOCUMENT = 'DOCUMENT',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  LINK = 'LINK'
}

export type ProductMedia = {
  main: boolean
  name: Option<string>
  type: ProductMediaType
  url: string
  groupBy: Option<string>
} & DbInfoFields

export interface ProductImages {
  mainImageUrl: string
  mainImages?: ProductMedia[]
  secondaryImages?: ProductMedia[]
  secondaryImageUrl?: string
}

export enum ProductType {
  BUNDLE = 'BUNDLE',
  CONFIGURABLE = 'CONFIGURABLE',
  CONFIGURATION = 'CONFIGURATION',
  REGULAR = 'REGULAR'
}

export interface BundleDTO {
  productId: string
  quantity: number
}

export interface ProductContentDTO {
  hidden: boolean
  name: string
  value: string
}

export interface ProductPrice {
  price: number
  originalPrice: number
}

export type ProductLightDTO = {
  articleNumber: string
  brand: Option<BrandLight>
  categoryIds: Option<string[]>
  configurations: Option<ProductConfiguration[]>
  deliveryId: string
  id: string
  medias: Option<ProductMedia[]>
  name: string
  priceRequest: boolean
  serviceIds: Option<string[]>
  slug: string
  tags: Option<TagLightDTO[]>
  type: ProductType
} & DbInfoFields &
  ProductPrice

export type OldProductDTO = {
  active: boolean
  bundles: Option<BundleDTO[]>
  categoryIds: Option<string[]>
  channel: string
  description: string
  grandId: string
  geckoId: string
  gtin: string
  inStock: boolean
  main: boolean
  options: Option<FilterOptionDTO[]> | null
  popular: boolean
  publishedBy: Option<string>
  relatedProducts: Option<RelatedProductDTO[]>
  source: string
  specifications: Option<ProductContentDTO[]>
  stockLevel: Option<number>
  storeId: string
  tagIds: Option<string[]>
  variantGroup: Option<string>
} & MetaFields &
  ProductLightDTO

export interface ProductConfiguration {
  optional: boolean
  position: number
  variantGroupId: Option<string>
  label: Option<string>
}

export interface StoreProductFilterDTO {
  articleNumbers?: string[]
  brandIds?: string[]
  categoryIds?: string[]
  filters?: Partial<FilterDTO>[]
  gtins?: string[]
  maxPrice?: number
  minPrice?: number
  names?: string[]
  productIds?: string[]
  search?: string
  tagIds?: string[]
  types?: ProductType[]
}

export type StoreSearchProductFilterDTO = Pick<
  StoreProductFilterDTO,
  'brandIds' | 'categoryIds' | 'maxPrice' | 'minPrice'
> & {
  optionIds?: string[][] // [['color1Id', 'color2Id'], ['size1Id', 'size2Id']]
  text: string // search query
}

export interface ProductSearchFacets {
  brands: SearchBrandFacetDTO[]
  categories: SearchCategoryFacetDTO[]
  options: SearchOptionFacetDTO[]
  prices: SearchPriceFacetDTO[]
  minPrice: number | null
  maxPrice: number | null
}

export interface SearchPriceFacetDTO {
  from: number
  to: number
  count: number
}

export interface SearchOptionValueFacetDTO {
  id: string
  value: string
  count: number
}

export interface SearchOptionFacetDTO {
  name: string
  values: SearchOptionValueFacetDTO[]
}

export interface SearchBrandFacetDTO {
  id: string
  name: string
  count: number
}

export type SearchCategoryFacetDTO = SearchBrandFacetDTO & {
  ancestors: string[] | null
}

export interface ProductSearchResponseDTO {
  facets: Nullable<ProductSearchFacets>
  products: ProductLightDTO[]
  totalElements: number
  totalPages: number
}

export interface ProductAutocompleteDTO {
  imageUrl: string
  name: string
  price: number
  originalPrice: number
  slug: string
}

export enum FilterType {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER'
}

export interface FilterDTO {
  id?: string
  label: Option<string>
  name: string
  numberValues: number[]
  storeId?: string
  textValues: string[]
  type: FilterType
  visible: boolean
}

export interface OldStoreProductFilterDTO {
  brands: BrandLight[]
  options: Option<FilterOptionDTO[]>
}

export type DiscountDTO = {
  brandIds: Option<string[]>
  campaign: Option<boolean>
  categoryIds: Option<string[]>
  code: string
  name: string
  storeId: string
  tagIds: Option<string[]>
  value: number
} & DbInfoFields

export interface PaginatedResponse<T> {
  content: T
  totalElements: number
  totalPages: number
}

export type GetProductsRes = PaginatedResponse<ProductLightDTO[]>

export interface ErrorRes {
  error: string
}

export interface GetProductVariantsRes {
  variants: Option<OldProductDTO[]>
}

export interface RelatedProductItem {
  product: OldProductDTO
  related: RelatedProductDTO
}

export interface GetProductRelatedRes {
  related: RelatedProductItem[]
}

export interface GetProductBundlesRes {
  bundles: Option<CartItem[]>
}

export interface InitialProductsState {
  initialProductsState: GetProductsRes
  initialFilterState: Partial<StoreProductFilterDTO>
}

export interface StorefrontInitialProducts {
  state: InitialProductsState
}

export interface StorefrontRelatedProducts {
  relatedProducts: Option<ProductLightDTO[]>
}

export type BrandDTO = {
  description: string
  id: string
  imgUrl: string
  name: string
  slug: string
  thumbnailDescription: string
  thumbnailUrl: string
} & MetaFields

export interface BrandLight {
  id: string
  name: string
}

export interface BrandGroup {
  group: string
  children: Option<BrandDTO[]>
}

export enum Currency {
  DKK = 'DKK',
  EUR = 'EUR',
  GBP = 'GBP',
  NOK = 'NOK',
  SEK = 'SEK',
  'USD' = 'USD'
}

export enum OrderStatus {
  CANCELLED = 'CANCELLED',
  CREATED = 'CREATED',
  DELIVERED = 'DELIVERED',
  DRAFT = 'DRAFT',
  PAID = 'PAID',
  PARTIALLY_DELIVERED = 'PARTIALLY_DELIVERED'
}

export type OrderDeliveryProviderName = 'INGRID' | 'DEFAULT'

export interface OrderShippingDTO {
  providerSessionId?: string
  provider?: OrderDeliveryProviderName
}

export type OrderDTO = DbLogFields & {
  id: string
  billingAddress?: AddressDTO
  currency?: Currency
  customer: Option<CustomerDTO>
  discountCode?: string
  items?: OrderItemDTO[]
  orderAmount?: number
  orderDiscount?: number
  orderNumber?: Option<string>
  orderTaxAmount?: number
  paymentId?: string
  paymentProvider?: PaymentProviderName
  shippingAddress?: AddressDTO
  shippingCost?: number
  shippingProvider?: OrderDeliveryProviderName
  shippingId?: OrderShippingDTO['providerSessionId']
  status: OrderStatus
  storeId: string
}

export type CreateUpdateOrderDTO = Omit<OrderDTO, 'items' | 'customer' | 'id'> & {
  items: CreateOrderItemDTO[]
  customer: Option<CreatCustomerDTO>
}

export interface AddressDTO {
  address1: string
  address2: string
  attention: string
  country: string
  mobilePhone: string
  postalCode: string
  postalRegion: string
  reference: string
}

export interface CreateServiceItemDTO {
  quantity: number
  serviceId: string
  totalAmount: number
}

export interface OrderItemDTO {
  additionalInfo: string
  originalUnitPrice: number
  product: ProductLightDTO
  quantity: number
  services: ServiceItemDTO[] | null
  subItems: OrderItemDTO[] | null
  unitPrice: number
  vatRate: number
  name?: string
  brand?: string
  slug?: string
}

export interface ServiceItemDTO {
  service: ServiceDTO
  quantity: number
  totalAmount: number
}

export interface CreateOrderItemDTO {
  additionalInfo: string
  originalUnitPrice: number
  productId: string
  quantity: number
  services: CreateServiceItemDTO[] | null
  subItems: CreateOrderItemDTO[]
  unitPrice: number
  vatRate: number
  name?: string
  brand?: string
  slug?: string
}

export interface UpdateOrderStatusDTO {
  orderStatus: OrderStatus
}

export interface CredentialsDTO {
  email: string
  password: string
}

export interface CreateCustomerDTO {
  email: string
  firstName: string
  lastName: string
  type: CustomerType
}

export enum Gender {
  FEMAILE = 'FEMALE',
  MALE = 'MALE',
  UNDEFINED = 'UNDEFINED'
}

export enum CustomerType {
  BUSINESS = 'BUSINESS',
  CONSUMER = 'CONSUMER'
}

export interface CustomerAddressDTO {
  address1: Option<string>
  address2: Option<string>
  attention: Option<string>
  country: Option<string>
  label: Option<string>
  main: boolean
  mobilePhone: Option<string>
  postalCode: Option<string>
  postalRegion: Option<string>
  reference: Option<string>
}

export interface PhoneDTO {
  label: Option<string>
  number: Option<string>
  prefix: Option<string>
}

export interface CustomerDTO {
  id: string
  activationToken: Option<string>
  active: boolean
  addresses: CustomerAddressDTO[]
  dateOfBirth: Option<string>
  email: string
  firstName: string
  gender: Gender
  lastLogin: Option<string>
  lastName: string
  middleName: Option<string>
  organizationName: Option<string>
  password: Option<string>
  phones: PhoneDTO[]
  storeId: string
  type: CustomerType
}

export interface CustomerLightDTO {
  id: string
  firstName: string
  gender: Gender
  lastName: string
  organizationName: string
  storeId: string
  type: CustomerType
}

export interface CustomerUpdateDTO {
  addresses: Option<CustomerAddressDTO[]>
  dateOfBirth: Option<string>
  firstName: Option<string>
  gender: Option<Gender>
  lastName: Option<string>
  middleName: Option<string>
  organizationName: Option<string>
  phones: Option<PhoneDTO[]>
}

export interface CustomerAuthUpdateDTO {
  activationToken: Option<string>
  email: Option<string>
  newPassword: Option<string>
  oldPassword: Option<string>
}

export interface CreatCustomerDTO {
  email: string
  firstName: string
  lastName: string
  type: CustomerType
}

export interface CartState {
  cartLocale: Option<string>
  cartItems: CartItem[]
  cartTotal: number
  cartDiscountCode: DiscountDTO | null
  /** uniq id for every change */
  cartStateUuid: Option<string>
}

export type CartItem = ProductLightDTO & {
  // Used for bundles (subItems) because it can be qty: 3 for 1 parent product
  originalQty?: string
  qty?: string
  subItems?: CartItem[]
}

export interface AddToCartPayload {
  product: ProductLightDTO
  qty: string
}

export interface MarketplaceProductDTO {
  articleNumber: string
  brand: string
  channel: string
  gtin: string
  imgUrl: Option<string>
  inStock: boolean
  name: string
  originalPrice: number
  price: number
  stockLevel: number
  url: Option<string>
}
