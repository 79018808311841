import type { LinkProps } from '@chakra-ui/next-js'
import { Link } from '@chakra-ui/next-js'

export type GrandLinkProps = LinkProps & {
  isExternal?: boolean
}

export const GrandLink = ({ isExternal, ...props }: GrandLinkProps) => {
  return (
    <Link
      {...(isExternal ? { ...{ target: '_blank', rel: 'noopener noreferrer' } } : {})}
      {...props}
    />
  )
}
