import type { FC, ReactElement } from 'react'
import { Box, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react'

interface IGrandMenuProps {
  actions: {
    id: string
    label: string
    isAvailable: boolean
    icon: any
    onClick: () => void
    isHighlighted?: boolean
  }[]
  component: ReactElement
}

const GrandMenu: FC<IGrandMenuProps> = ({ actions, component }) => {
  const controls = actions.filter((i) => i.isAvailable)

  if (controls.length) {
    return (
      <Menu>
        <MenuButton as={Box} cursor="pointer">
          {component}
        </MenuButton>
        <MenuList zIndex={9999}>
          {controls.map((action) => (
            <MenuItem
              aria-label={action.label}
              icon={action.icon}
              key={action.id}
              onClick={action.onClick}>
              <Text
                fontSize={action.isHighlighted ? 'md' : 'sm'}
                fontWeight={action.isHighlighted ? 'bold' : 'normal'}>
                {action.label}
              </Text>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    )
  }
  return component
}

export default GrandMenu
