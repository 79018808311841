import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay
} from '@chakra-ui/react'
import type { ReactNode, Context } from 'react'
import { useContextSlideOut } from './useContextSlideOut'
import type { SlideOutContextType, SlideoutContextProps } from './types'

export interface SlideOutBasicControllerHooks<C, F> extends SlideoutContextProps {
  footer: ReactNode
  context: Context<SlideOutContextType<C, F> | null>
}
const SlideOutBasicController = <C, F>({
  children,
  footer,
  context
}: SlideOutBasicControllerHooks<C, F>) => {
  const { isHidden, closeSlideOut, title, size } = useContextSlideOut(context)

  return (
    <Drawer isOpen={!isHidden} onClose={closeSlideOut} placement="right" {...{ size }}>
      <DrawerOverlay />
      <DrawerContent
        bg="background.surface"
        transition="max-width 0.1s cubic-bezier(.17,.67,.83,.67)">
        <DrawerHeader color="primary" fontWeight="medium">
          {title}
        </DrawerHeader>
        <DrawerCloseButton />
        <DrawerBody py={0}>{children}</DrawerBody>
        <DrawerFooter>{footer}</DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

export default SlideOutBasicController
