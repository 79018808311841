import type { FC, ReactElement } from 'react'
import { Box } from '@chakra-ui/react'
import { MAX_CONTENT_WIDTH } from '../constants'

interface IGrandBannerProps {
  isShown: boolean
  content: string | ReactElement
  position?: 'top' | 'bottom'
}

const Banner: FC<IGrandBannerProps> = ({ content, isShown, position = 'top', ...props }) => {
  return (
    <Box
      {...props}
      bg="background.default"
      borderRadius="sm"
      borderWidth="1px"
      boxShadow="sm"
      display={isShown ? 'initial' : 'none'}
      left="50%"
      maxW={MAX_CONTENT_WIDTH}
      minH={{
        base: `60px`,
        md: '80px'
      }}
      mx="auto"
      p={{
        base: 4,
        md: 10
      }}
      position="fixed"
      transform="translateX(-50%)"
      w="100%"
      zIndex={1300}
      {...(position === 'top' && {
        top: {
          base: '9vh',
          sm: '15vh'
        }
      })}
      {...(position === 'bottom' && { bottom: '20px' })}>
      {content}
    </Box>
  )
}

export default Banner
