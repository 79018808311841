import type { FC, ReactElement } from 'react'
import type { StackProps } from '@chakra-ui/react'
import { StackDivider, VStack } from '@chakra-ui/react'
import GrandHeading from './GrandHeading'

interface ITitledContentProps extends Omit<StackProps, 'content'> {
  title: string
  content: ReactElement
}

const TitledContent: FC<ITitledContentProps> = ({ title, content, ...props }) => {
  return (
    <VStack divider={<StackDivider />} {...props}>
      <GrandHeading fontSize="xl" headingTag="h5" my={1} {...{ title }} />
      {content}
    </VStack>
  )
}

export default TitledContent
