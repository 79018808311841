import type { FC } from 'react'
import type { IconButtonProps } from '@chakra-ui/react'
import { IconButton } from '@chakra-ui/react'
import GrandTooltip from './GrandTooltip'

export interface GrandIconButtonProps extends IconButtonProps {
  tooltip?: string
}

const GrandIconButton: FC<GrandIconButtonProps> = (props) => {
  return (
    <GrandTooltip label={props.tooltip}>
      <IconButton
        _active={{
          transform: 'scale(0.85)'
        }}
        _focus={{
          boxShadow: 'none',
          outline: 'none'
        }}
        _hover={{ opacity: 0.75 }}
        borderRadius="50%"
        fontSize="xl"
        minW="unset"
        shadow="lg"
        {...props}
      />
    </GrandTooltip>
  )
}

export default GrandIconButton
