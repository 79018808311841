import type { FC } from 'react'
import {
  Box,
  HStack,
  List,
  ListIcon,
  ListItem,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
  VStack
} from '@chakra-ui/react'
import { MdCheckCircle, MdOutlineDoNotDisturbAlt } from 'react-icons/md'
import { BiChevronDown } from 'react-icons/bi'
import type { Accept, DropzoneOptions, FileRejection } from 'react-dropzone'
import { useDropzone } from 'react-dropzone'
import { useOverridesContext } from '../lib/overrides/hooks'
import { overrideText } from '../lib/overrides'

export interface IGrandDropZone {
  accept?: Accept
  onDrop?: (accepted: File[]) => void
  onDropRejected?: (rejected: FileRejection[]) => void
  accepted: File[]
  rejected: FileRejection[]
  options?: DropzoneOptions
  dropZoneStyles?: any

  [styleProp: string]: any
}

export interface GrandDropZoneOverrides {
  dropzoneDropMsg?: string
  dropzoneAcceptedFiles?: string
  dropzoneReadyMsg?: string
  dropzoneRejectedMsg?: string
}

const GrandDropZone: FC<IGrandDropZone> = ({
  onDrop = () => undefined,
  onDropRejected = () => undefined,
  accepted = [],
  rejected = [],
  options = null,
  dropZoneStyles = null,
  ...styleProps
}) => {
  const overrides = useOverridesContext<keyof GrandDropZoneOverrides>()
  // ***** DROPZONE CONTROLS *****
  const { getRootProps, getInputProps, acceptedFiles, fileRejections } = useDropzone({
    onDropAccepted: onDrop,
    onDropRejected,
    accept: { 'image/*': ['.png', '.jpeg', '.jpg', '.webp'] },
    ...options
  })

  const acceptedFileItems = accepted.map((file) => (
    <ListItem color="status.success" key={file.name}>
      <ListIcon as={MdCheckCircle} />
      {file.name}
    </ListItem>
  ))

  const fileRejectionItems = rejected.map(({ file, errors }) => (
    <ListItem key={file.name}>
      <List color="status.error">
        {errors.map((e) => (
          <Tooltip key={e.code} label={e.message}>
            <ListItem>
              <ListIcon as={MdOutlineDoNotDisturbAlt} />
              {file.name}
            </ListItem>
          </Tooltip>
        ))}
      </List>
    </ListItem>
  ))
  return (
    <VStack {...styleProps}>
      <VStack
        bg="background.surface"
        borderColor="text.mild"
        borderRadius="sm"
        borderStyle="dotted"
        borderWidth="1px"
        cursor="pointer"
        p={6}
        spacing={4}
        textAlign="center"
        {...getRootProps()}
        {...dropZoneStyles}>
        <input {...getInputProps()} />
        <Text>
          {overrideText(
            'Släpp dina filer här, eller tryck för att bläddra',
            overrides?.dropzoneDropMsg
          )}
        </Text>
        <Text color="text.mild" fontSize="sm">
          {overrideText(
            'Accepterar filtillägg: *.png, *.jpg, *.jpeg and *.webp',
            overrides?.dropzoneAcceptedFiles
          )}
        </Text>
      </VStack>
      <VStack color="text.mild" fontSize="sm">
        {acceptedFiles.length > 4 ? (
          <Popover placement="bottom">
            <PopoverTrigger>
              <HStack _hover={{ opacity: 0.75 }} color="status.success" cursor="pointer">
                <MdCheckCircle />
                <Box>
                  {acceptedFiles.length}{' '}
                  {overrideText('filer redo att laddas upp', overrides?.dropzoneReadyMsg)}
                </Box>
                <BiChevronDown />
              </HStack>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverBody>
                <List>{acceptedFileItems}</List>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        ) : (
          <List>{acceptedFileItems}</List>
        )}
        {fileRejections.length > 4 ? (
          <Popover placement="bottom">
            <PopoverTrigger>
              <HStack _hover={{ opacity: 0.75 }} color="status.error" cursor="pointer">
                <MdOutlineDoNotDisturbAlt />
                <Box>
                  {fileRejections.length}{' '}
                  {overrideText('filer avvisade', overrides?.dropzoneRejectedMsg)}
                </Box>
                <BiChevronDown />
              </HStack>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverBody>
                <List>{fileRejectionItems}</List>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        ) : (
          <List>{fileRejectionItems}</List>
        )}
      </VStack>
    </VStack>
  )
}

export default GrandDropZone
