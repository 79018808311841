import type { FC } from 'react'
import type { TooltipProps } from '@chakra-ui/react'
import { Tooltip } from '@chakra-ui/react'

interface IGrandTooltipProps extends TooltipProps {
  label?: string
}

const GrandTooltip: FC<IGrandTooltipProps> = ({ children, label, ...props }) => {
  return (
    <Tooltip
      bg="background.surface"
      borderColor="background.surface"
      borderRadius="sm"
      borderWidth="1px"
      color="text.default"
      label={label || ''}
      px={2}
      py={1.25}
      shadow="xs"
      {...props}>
      {children}
    </Tooltip>
  )
}

export default GrandTooltip
