import type { FC } from 'react'
import { useState } from 'react'
import type { FlexProps } from '@chakra-ui/react'
import { Flex } from '@chakra-ui/react'
import { clsx } from 'clsx'
import { createCn } from 'shared-utils'

const cn = createCn('carousel-item')

export type CarouselItemProps = FlexProps & {
  setTrackIsActive: any
  setActiveItem: any
  activeItem: any
  constraint: any
  itemWidth: any
  positions: any
  gap: number
  index: number
}

const CarouselItem: FC<CarouselItemProps> = ({
  setTrackIsActive,
  setActiveItem,
  activeItem,
  constraint,
  itemWidth,
  positions,
  children,
  index,
  gap,
  className,
  ...props
}) => {
  const [userDidTab, setUserDidTab] = useState(false)
  const handleFocus = () => setTrackIsActive(true)

  const handleBlur = () => {
    userDidTab && index + 1 === positions.length && setTrackIsActive(false)
    setUserDidTab(false)
  }

  const handleKeyUp = (event: any) =>
    event.key === 'Tab' && !(activeItem === positions.length - constraint) && setActiveItem(index)

  const handleKeyDown = (event: any) => {
    if (event.key === 'Tab') {
      setUserDidTab(true)
    }
  }

  return (
    <Flex
      _notLast={{
        mr: `${gap}px`
      }}
      className={clsx(cn(), className)}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
      py="4px"
      w={`${itemWidth}px`}
      {...props}>
      {children}
    </Flex>
  )
}

export default CarouselItem
