import { useEffect, useRef, useState } from 'react'

function useOnScreen(ref: any) {
  const [isIntersecting, setIsIntersecting] = useState(false)
  const observer = useRef<IntersectionObserver | null>(null)

  useEffect(() => {
    observer.current = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting)
    })
    if (ref?.current) {
      observer.current.observe(ref.current)
    }
    return () => {
      observer.current?.disconnect()
    }
  }, [observer, ref])

  return isIntersecting
}

export default useOnScreen
