import { Currency } from 'ecosystem'

export const DEFAULT_COUNTRY = 'SE'
export const DEFAULT_CURRENCY = Currency.SEK
export const IS_PRODUCTION = process.env.NODE_ENV === 'production'
export const VAT_RATES = {
  SE: 25,
  NO: 25,
  FI: 25.5
}
