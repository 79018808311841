import type { FC, ReactElement } from 'react'
import { useMemo, forwardRef } from 'react'
import type { TabsProps } from '@chakra-ui/react'
import {
  Box,
  Button,
  HStack,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useMultiStyleConfig,
  useTab
} from '@chakra-ui/react'

export interface TabItemType {
  label: string
  component: ReactElement
  isAvailable: boolean
  icon?: any
}

interface GrandTabsProps extends Partial<TabsProps> {
  tabs: TabItemType[]
  tabProps?: any
  panelProps?: any
}

const GrandTabs: FC<GrandTabsProps> = ({ tabs, tabProps = null, panelProps = null, ...props }) => {
  const filteredTabs = useMemo(() => tabs.filter((e) => e.isAvailable), [tabs])

  return (
    <Tabs
      bg="background.default"
      borderBottomLeftRadius="sm"
      borderBottomRightRadius="sm"
      display="flex"
      flexDirection="column"
      h="100%"
      isFitted
      isLazy
      p={4}
      w="100%"
      {...props}>
      <TabList border="none" display="flex" justifyContent="flex-start" pt={4}>
        {filteredTabs.map((tabItem) => (
          <CustomTab
            _focus={{
              outline: '1px solid',
              outlineColor: 'primary'
            }}
            icon={tabItem.icon}
            key={tabItem.label}
            {...tabProps}>
            {tabItem.label}
          </CustomTab>
        ))}
      </TabList>
      <TabPanels h="100%">
        {filteredTabs.map((tabItem) => (
          <TabPanel borderTop="none" h="100%" key={tabItem.label} py={12} {...panelProps}>
            {tabItem.component}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  )
}

interface CustomTabProps {
  icon: any
}

export default GrandTabs

const CustomTab = forwardRef<HTMLButtonElement, CustomTabProps>(({ icon, ...props }, ref) => {
  // 1. Reuse the `useTab` hook
  const tabProps = useTab({ ...props, ref })

  // 2. Hook into the Tabs `size`, `variant`, props
  const styles = useMultiStyleConfig('Tabs', tabProps)

  return (
    <Button
      __css={styles.tab}
      _selected={{
        color: 'primary',
        borderBottomWidth: 1,
        bg: 'background.primary'
      }}
      borderBottomWidth={0}
      borderColor="background.dark"
      borderTopLeftRadius="sm"
      borderTopRightRadius="sm"
      color="text.mild"
      fontSize="md"
      {...tabProps}>
      <HStack justifyContent="center" mr={2} px={6}>
        <Box mr={2}>{tabProps.children}</Box>
        {icon ? <Box>{icon}</Box> : null}
      </HStack>
    </Button>
  )
})

CustomTab.displayName = 'CustomTab'
