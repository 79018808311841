import { useEffect, useRef } from 'react'

export const useRefUpdated = <Value = any>(value: Value) => {
  const ref = useRef<Value>(value)

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref
}
